<template>
  <div style="padding-top: 10px;">
    <mds-loader
      v-if="showMainLoader"
      aria-label="Medium Loader"
    />
    <div v-else>
      <mds-layout-grid
        id="search-bar"
        style="margin-left: 21px; top: -4px; position: relative;"
      >
        <mds-row>
          <mds-col>
            <mds-search-field
              v-model="searchString"
              placeholder="Search Inputs: Use keywords like 'cols', 'key', 'roots', 'feed', 'source' and 'value'"
              variation="input"
              selection
              label="Search"
              :selected-items="searchFilters"
              @mds-search-field-input-cleared="clearAll"
              @mds-search-field-item-deleted="deleteItem"
              @keyup.enter="addSimpleStringFilterTag"
              @focus="searchFocused = true"
            />
            <mds-search-results
              v-if="showSearchResults"
              width="500px"
              style="z-index: 100; height: 150px;"
            >
              <mds-search-results-section
                v-for="searchKey in Object.keys(tempSearchData)"
                id="search-results"
                :key="searchKey"
                :title="searchKey === 'cols' ? 'Columns' : searchKey === 'feed' ? 'Feeds' : searchKey === 'key'
                  ? 'Keys' : searchKey.charAt(0).toUpperCase() + searchKey.slice(1)"
              >
                <template v-if="searchKey === 'key'">
                  <mds-list-group
                    v-for="feedKey in Object.keys(tempSearchData[searchKey])"
                    :key="feedKey"
                    size="small"
                  >
                    <mds-list-group-item
                      :text="feedKey"
                      :metadata-below="Array.from(tempSearchData[searchKey][feedKey])"
                      @mousedown.native="addFilterTag(searchKey, feedKey)"
                    />
                  </mds-list-group>
                </template>
                <template v-else>
                  <mds-list-group
                    v-for="field in tempSearchData[searchKey]"
                    :key="field"
                    size="small"
                  >
                    <mds-list-group-item
                      :text="field"
                      :metadata-below="searchKey === 'key' ? Object.keys(field).map(k => field[k]) : []"
                      @mousedown.native="addFilterTag(searchKey, field)"
                    />
                  </mds-list-group>
                </template>
              </mds-search-results-section>
            </mds-search-results>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <ve-table
        ref="tableRef"
        max-height="calc(100vh - 200px)"
        style="width:100%; word-break: keep-all; margin-top: 10px; position: relative;"
        :border-x="true"
        :border-y="true"
        :border-around="true"
        :columns="cols"
        :table-data="tableData"
        :scroll-width="0"
        row-key-field-name="index"
        :cell-style-option="cellStyleOption"
        :cell-autofill-option="cellAutofillOption"
        :edit-option="editOption"
        :row-style-option="rowStyleOption"
        :column-width-resize-option="columnWidthResizeOption"
        :event-custom-option="eventCustomOption"
        :contextmenu-body-option="contextmenuBodyOption"
        :clipboard-option="clipboardOption"
        :virtual-scroll-option="virtualScrollOption"
      />
      <mds-dialog
        v-if="inputToggle"
        id="hello"
        v-model="inputToggle"
        action-required
        title="Edit Input"
      >
        <template #mds-dialog-supplemental-content>
          <mds-loader
            v-if="showInputLoader"
            size="small"
            aria-label="Small Loader"
          />
          <div
            v-else-if="currentInputIsVariableType"
            :class="readOnly ? 'disabled' : ''"
          >
            <mds-input
              v-model="currentInputToEdit.name"
              label="Variable Name (case-sensitive)"
              disabled
            />
            <div v-if="variableDataType === 'number'">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: Number
              </mds-tag>
              <mds-input
                v-model="currentInputToEdit.body.value"
                label="Value"
                :error="varValueError"
                :error-text="['Entered value is not valid per the selected data-type.']"
              />
            </div>
            <div v-if="variableDataType === 'boolean'">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: Boolean
              </mds-tag>
              <mds-fieldset
                variation="radio-group"
                legend="Value"
                horizontal
              >
                <mds-radio-button
                  v-model="currentInputToEdit.body.value"
                  value="true"
                >
                  True
                </mds-radio-button>
                <mds-radio-button
                  v-model="currentInputToEdit.body.value"
                  value="false"
                >
                  False
                </mds-radio-button>
              </mds-fieldset>
            </div>
            <div v-if="variableDataType === 'object'">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: JSON
              </mds-tag>
              <mds-textarea
                v-model="currentInputToEdit.body.value"
                label="Value"
                :error="varValueError"
                :error-text="['Entered value is not valid per the selected data-type.']"
              />
            </div>
            <div v-if="variableDataType === 'string'">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: String
              </mds-tag>
              <mds-input
                v-model="currentInputToEdit.body.value"
                label="Value"
              />
            </div>
            <div v-if="variableDataType === 'date'">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: Date
              </mds-tag>
              <mds-date-picker
                v-model="currentInputToEdit.body.value"
                :min-max-dates="minMaxDates"
                label="Date"
                :date-format="{year: 'numeric', month: '2-digit', day: '2-digit', locale: 'fr-CA'}"
              />
            </div>
          </div>
          <div
            v-else
            :class="readOnly ? 'disabled' : ''"
          >
            <mds-input
              v-model="currentInputToEdit.name"
              label="Variable Name (case-sensitive)"
              disabled
            />
            <mds-select
              v-model="selectedSource"
              label="Source"
              :options="dataSetForSources"
              @change="sourceChangeListener()"
            />
            <mds-select
              v-model="selectedFeed"
              label="Feed"
              :options="dataSetForFeed"
              :error="inputFeedNotAvailable"
              :error-text="errorMessageForFeed"
              @change="feedChangeListener()"
            />
            <mds-combo-box
              v-model="selectedColumns"
              multiple
              label="Columns"
              :display-top="true"
              :data-set="dataSetForColumns"
              :disabled="disableColumns"
              :error="inputFeedNotAvailable"
            />
            <div v-if="currentInputIsForwardCurve && currentFeedRoots.length > 0">
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: Forward Curves
              </mds-tag>
              <div style="display: flex; flex-wrap: wrap;">
                <div style="width: 50%;">
                  <mds-combo-box
                    v-if="notNewKey"
                    v-model="selectedRoots"
                    multiple
                    :display-top="true"
                    :data-set="currentFeedRoots"
                    label="Root(s)"
                    @keydown.tab.prevent="disableTab"
                  />
                  <mds-input
                    v-else-if="!notNewKey && currentInputToEdit.type === 'Publish'"
                    v-model="addedNewKey"
                    label="Add new key"
                    @keydown.tab.prevent="disableTab"
                    @focusout="addNewLabel($event)"
                  />
                </div>
                <div style="width: 50%;">
                  <div v-if="!notNewKey && currentInputToEdit.type === 'Publish'">
                    <mds-button
                      id="remove-add-new-key"
                      variation="icon-only"
                      icon="remove"
                      type="button"
                      style="margin-top: 25px; margin-left: 5%;"
                      @click.stop="removeAddNewKey()"
                    />
                    <mds-tooltip
                      v-model="toggleRemoveAddNewKey"
                      triggered-by="remove-add-new-key"
                      :position="['right-center']"
                    >
                      Remove new key
                    </mds-tooltip>
                  </div>
                  <div v-else-if="currentInputToEdit.type === 'Publish'">
                    <mds-button
                      id="add-new-key"
                      variation="icon-only"
                      icon="plus"
                      type="button"
                      style="margin-top: 25px; margin-left: 5%;"
                      @click.stop="addNewKey()"
                    />
                    <mds-tooltip
                      v-model="toggleaddNewKey"
                      triggered-by="add-new-key"
                      :position="['right-center']"
                    >
                      Add new key
                    </mds-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <mds-tag
                size="medium"
                style="margin-bottom: 10px;"
              >
                Variable Type: Timeseries Data
              </mds-tag>
              <div>
                <mds-input
                  v-for="(key, index) in Object.keys(selectedKeys)"
                  :key="index"
                  v-model="selectedKeys[key]"
                  :label="key"
                  :error="inputFeedNotAvailable"
                />
              </div>
            </div>
            <div
              v-if="isDataNode"
            >
              <div
                style="margin-top: 3%;"
              >
                <span><strong>Runs: </strong></span>
              </div>
              <div
                style="margin-top: 1%;"
              >
                <mds-checkbox
                  checked
                  disabled
                  value="accept"
                >
                  When data arrives
                </mds-checkbox>
              </div>
              <div style="margin-top: 3%;">
                <div style="display: flex;">
                  <div style="width: 15%;">
                    <mds-checkbox
                      :checked="expireSet === true || expireSet === 'true'"
                      value="true"
                      @change="expireSet = !expireSet"
                    >
                      At
                    </mds-checkbox>
                  </div>
                  <div style="width: 40%;">
                    <mds-input
                      v-model="expireTime"
                      :disabled="!expireSet"
                      label=""
                      type="time"
                    />
                    <span>({{ manager._priv.tz }})</span>
                  </div>
                  <div style="width: 45%; padding-left: 2%;">
                    <span> if data hasn't arrived</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="cancelEditPopup()"
            >
              Cancel
            </mds-button>
            <mds-button
              :class="readOnly ? 'disabled' : ''"
              type="button"
              variation="primary"
              :disabled="!allParametersAdded({
                type: currentInputToEdit.body.type,
                feed: selectedFeed,
                source: selectedSource,
                columns: selectedColumns,
                keys: selectedKeys,
                roots: selectedRoots,
                value: currentInputToEdit.body.value,
              })"
              @click="saveParameter(!allParametersAdded({
                type: currentInputToEdit.body.type,
                feed: selectedFeed,
                source: selectedSource,
                columns: selectedColumns,
                keys: selectedKeys,
                roots: selectedRoots,
                value: currentInputToEdit.body.value,
              }))"
            >
              Save
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showCopyDialog"
        id="showCopyDialog"
        v-model="showCopyDialog"
        action-required
        title=""
        width="500px"
      >
        <mds-layout-grid style="margin: 10px;">
          <mds-row>
            <mds-col>
              <h3>Seleted Row Name</h3>
            </mds-col>
            <mds-col>
              <h3>Copied Row Name</h3>
            </mds-col>
          </mds-row>
          <mds-row
            v-for="(column, index) in inputData.slice(currentRowToCopyIndex.start, currentRowToCopyIndex.end + 1)"
            :key="index"
            style="margin-top: 10px;"
          >
            <mds-col>
              <span> {{ column.name }} </span>
            </mds-col>
            <mds-col>
              <div>
                <mds-input
                  v-model="column.copiedRowName"
                  hidden-label
                  :label="column.name"
                >
                  {{ column.copiedRowName }}
                </mds-input>
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showCopyDialog=!showCopyDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :disabled="copyRowButtonDisabled"
              @click="copyRows(copyRowButtonDisabled)"
            >
              Yes, Proceed
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showDeleteDialog"
        id="showDeleteDialog"
        v-model="showDeleteDialog"
        action-required
        title="Delete Rows"
      >
        <span v-if="!deleteRowButtonDisabled"> Are you sure you want to delete the following rows?</span>
        <span v-else> Workflow restart is in progress. Please wait till workflow restart complete.</span>
        <mds-table
          v-if="!deleteRowButtonDisabled"
          row-numbers
          style="margin-top: 5px;"
        >
          <mds-thead hidden-header>
            <mds-th> Name </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr
              v-for="(rowToDelete, index) in inputData.slice(currentRowToDeleteIndex.start, currentRowToDeleteIndex.end + 1)"
              :key="index"
              :row-number="index+1"
            >
              <mds-td> {{ rowToDelete.name }} </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <span v-if="!deleteRowButtonDisabled && showRunningJobMsgDel">
          <br><br>
          <strong>
            Note: </strong>The current curve is in a running state. We will first stop the curve and then proceed with deletion.
        </span>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showDeleteDialog=!showDeleteDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :disabled="deleteRowButtonDisabled"
              @click="deleteRows"
            >
              Yes, Proceed
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showNewRowDialog"
        id="showNewRowDialog"
        v-model="showNewRowDialog"
        action-required
        title=""
        width="500px"
      >
        <mds-layout-grid style="margin: 10px;">
          <mds-row>
            <mds-col>
              <div>
                <mds-input
                  v-model="newRowName"
                  required
                  label="New Row Name"
                />
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showNewRowDialog=!showNewRowDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              :disabled="newRowButtonDisabled"
              @click="addNewRow(false, newRowButtonDisabled)"
            >
              Yes, Proceed
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showChangeDialog"
        id="showChangeDialog"
        v-model="showChangeDialog"
        action-required
        title="Saving Rows"
      >
        <template #mds-dialog-supplemental-content>
          <mds-table v-if="psgToAdd.length > 0">
            <mds-thead>
              <mds-th> Adding Below Rows </mds-th>
            </mds-thead>
            <mds-tbody>
              <mds-tr
                v-for="(row, index) in psgToAdd"
                :key="index + row.name + row.id"
              >
                <mds-td
                  size="small"
                  style="background-color: #e5f7eb;"
                >
                  <mds-inline-message variation="success">
                    {{ row['name'] }}
                  </mds-inline-message>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
          <mds-table v-if="modifiedParameters.length > 0">
            <mds-thead>
              <mds-th> Modifying Below Rows </mds-th>
            </mds-thead>
            <mds-tbody>
              <mds-tr
                v-for="(name, index) in getModifiedRowNames"
                :key="index + name"
              >
                <mds-td
                  size="small"
                  style="background-color: #fef9e5;"
                >
                  <mds-inline-message variation="warning">
                    {{ name }}
                  </mds-inline-message>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
          <mds-table v-if="renamedRows.length > 0">
            <mds-thead>
              <mds-th> Renaming Rows </mds-th>
            </mds-thead>
            <mds-tbody>
              <mds-tr
                v-for="(row, index) in renamedRows"
                :key="index + row.name"
              >
                <mds-td
                  size="small"
                  style="background-color: #fef9e5;"
                >
                  <mds-inline-message variation="warning">
                    {{ row.name }}
                  </mds-inline-message>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
          <mds-table v-if="deletedParameters.psgIds.length > 0">
            <mds-thead>
              <mds-th> Removing Below Rows </mds-th>
            </mds-thead>
            <mds-tbody>
              <mds-tr
                v-for="(row, index) in getDeletedRowNames"
                :key="index + row.name + row.id"
              >
                <mds-td
                  size="small"
                  style="background-color: #ffe5e5;"
                >
                  <mds-inline-message variation="error">
                    {{ row['name'] }}
                  </mds-inline-message>
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
        </template>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showChangeDialog=!showChangeDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              type="button"
              variation="primary"
              @click="updateInputs"
            >
              Save
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showRenameDialog"
        id="showRenameDialog"
        v-model="showRenameDialog"
        action-required
        title=""
        width="500px"
      >
        <mds-layout-grid style="margin: 10px;">
          <mds-row>
            <mds-col>
              <div :class="readOnly ? 'disabled' : ''">
                <mds-input
                  v-model="currentRenamedRow.value"
                  required
                  :label="currentRenamedRow.type"
                />
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <template #mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="secondary"
              @click="showRenameDialog=!showRenameDialog"
            >
              Cancel
            </mds-button>
            <mds-button
              :class="readOnly ? 'disabled' : ''"
              type="button"
              variation="primary"
              :disabled="currentRenamedRow.value.trim().length === 0"
              @click="saveRenamedRow(currentRenamedRow.value.trim().length === 0)"
            >
              Yes, Proceed
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
      <mds-dialog
        v-if="showInputSetMigrationDialog"
        v-model="showInputSetMigrationDialog"
        action-required
        title="Migrate Input Rows"
        size="medium"
        width="500px"
      >
        <WorkflowInputSetMigrationVue
          :source-workflow="workflowInfo"
          :selected-inputs="selectedInputsForMigration"
        />
      </mds-dialog>
    </div>
    <mds-notification-container v-if="showInputWarningNotification">
      <mds-notification
        key="warning-default"
        variation="error"
        title="Invalid Name"
        @mds-notification-dismissed="showInputWarningNotification = false"
      >
        {{ $t('labels.common.enterValidName') }}
      </mds-notification>
    </mds-notification-container>
  </div>
</template>

<script>
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsDialog from '@mds/dialog';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsSelect from '@mds/select';
import MdsDatePicker from '@mds/date-picker';
import MdsRadioButton from '@mds/radio-button';
import MdsTextarea from '@mds/textarea';
import { MdsTag } from '@mds/tag';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsInlineMessage from '@mds/inline-message';
import MdsSearchField from '@mds/search-field-v4';
import { MdsSearchResults, MdsSearchResultsSection } from '@mds/search-results';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import axios from 'axios';
import _ from 'lodash';
import { VeTable } from 'vue-easytable';
import timeZone from '../constants/timezone';
import { _editDatasetCB, _buildPsg } from '../scripts/utils/Manager';
import { _paramSetToServerModel } from '../scripts/utils/mpapi.es6';
import 'vue-easytable/libs/theme-default/index.css';
import { ParameterSet } from '../scripts/utils/wf';

import WorkflowInputSetMigrationVue from '../InputMigration/WorkflowInputSetMigration.vue';

const defaultOption = {
  text: '*** Choose one ***',
  value: 'default',
};

const incompleteRowMessage = 'Please complete the input rows';
const width = 300;

export default {
  name: 'WorkflowInputs',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsDialog,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
    MdsComboBox,
    MdsSelect,
    MdsDatePicker,
    MdsFieldset,
    MdsRadioButton,
    MdsTextarea,
    MdsTag,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsInlineMessage,
    MdsSearchField,
    MdsSearchResults,
    MdsSearchResultsSection,
    MdsListGroup,
    MdsListGroupItem,
    VeTable,
    WorkflowInputSetMigrationVue,
    MdsNotification,
    MdsNotificationContainer,
  },
  filters: {
    showColumnData(value) {
      try {
        let str = '';
        if (!value) {
          return str;
        }
        if (Object.hasOwn(value, 'value')) {
          let date = null;
          try {
            JSON.stringify(JSON.parse(value.value));
            return value.value;
          } catch {
            if (/\d{4}-\d{2}-\d{2}/gm.test(value.value)) {
              date = new Date(value.value);
              // eslint-disable-next-line eqeqeq
              if (date == 'Invalid Date') {
                return value.value;
              }
              return new Intl.DateTimeFormat('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date);
            }
            return value.value;
          }
        }

        // append keys
        if (Array.isArray(value.key)) {
          str = value.key.join(', ');
        } else {
          Object.keys(value.key).forEach((key, index) => {
            if (index === 0) {
              str += value['key'][key];
            } else {
              str += `, ${value['key'][key]}`;
            }
          });
        }

        // append cols
        str += '<br>';
        str += value['cols'].join(', ');

        // append feed
        str += '<br>';
        str += value['feed'];

        return str;
      } catch {
        return '';
      }
    },
  },
  props: {
    workflowId: {
      type: String,
      default: '-1',
    },
    operation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showInputWarningNotification: false,
      columns: [{ prop: 'name', name: 'Name' }, { prop: 'description', name: 'Description', width: '20%' }],
      rows: [],
      virtualScrollOption: {
        enable: true,
        minRowHeight: 80,
        bufferScale: 2,
      },
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: false,
      },
      clipboardOption: {
        copy: false,
        paste: false,
        cut: false,
        delete: false,
      },
      currentRowToCopyIndex: {
        start: -1,
        end: -1,
      },
      currentRowToDeleteIndex: {
        start: -1,
        end: -1,
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.key.includes('(Data)')) {
            return 'table-body-cell-data';
          }

          if (column.key.includes('(Publish)')) {
            return 'table-body-cell-publish';
          }

          if (column.key.includes('(Var)')) {
            return 'table-body-cell-var';
          }
        },
      },
      cellAutofillOption: {
        directionX: false,
        directionY: true,
        beforeAutofill: ({
          direction,
          sourceSelectionRangeIndexes,
          targetSelectionRangeIndexes,
          sourceSelectionData,
          targetSelectionData,
        }) => {
          if (sourceSelectionRangeIndexes.startColIndex < 2) {
            this.setRangeCellSelection(sourceSelectionRangeIndexes.startRowIndex, sourceSelectionRangeIndexes.endRowIndex,
              this.cols[2].key, this.cols[sourceSelectionRangeIndexes.endColIndex].key);
            return false;
          }
          return true;
        },
        afterAutofill: ({
          direction,
          sourceSelectionRangeIndexes,
          targetSelectionRangeIndexes,
          sourceSelectionData,
          targetSelectionData,
        }) => {
          const sourceRowName = this.inputData[sourceSelectionRangeIndexes.startRowIndex].name;
          let count = 1;
          const rowNames = this.inputData.filter(r => r.name.length > 0).map(r => r.name);
          for (let i = targetSelectionRangeIndexes.startRowIndex; i <= targetSelectionRangeIndexes.endRowIndex; i++) {
            if (this.inputData[i].name.length === 0) {
              let rowName = `Copy_of_${sourceRowName}_${count}`;
              if (rowNames.includes(rowName)) {
                rowName += `_${Math.floor(Math.random() * 90000) + 10000}`;
              }
              if (rowName.length > 50) {
                rowName = _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10).join('') + _.sampleSize('0123456789', 5).join('');
              }
              this.inputData[i].name = rowName;
              this.inputData[i].prevName = rowName;
              count++;
            }
            this.inputData[i]._isModified = true;
          }
          this.resetCopiedRowName();
        },
      },
      editOption: {
        beforeStartCellEditing: ({ row, column, cellValue }) => {
          this.showInputWarningNotification = false;
          return !this.readOnly;
        },
        beforeCellValueChange: ({ row, column, changeValue }) => {
          if (column.key === 'name' && (changeValue.trim().length === 0 || /^\w+/gm.exec(changeValue) === null
            || changeValue.length > 50)) {
            this.showInputWarningNotification = true;
            return false;
          }
          if (column.key === 'description' && changeValue.length > 255) {
            return false;
          }
          const rowNames = this.inputData.map(r => r.name.trim());
          if (column.key === 'name' && rowNames.includes(changeValue)) {
            return false;
          }
          return true;
        },
        afterCellValueChange: ({ row, column, changeValue }) => {
          row._isModified = true;
          return true;
        },

      },
      cols: [{
        field: 'index',
        key: 'index',
        title: '',
        width: 50,
        fixed: 'left',
        align: 'center',
        renderBodyCell: ({ row, column, rowIndex }, h) => row.index + 1,
      }, {
        field: 'name',
        title: 'Name',
        key: 'name',
        width,
        edit: true,
      }, {
        field: 'description', title: 'Description', key: 'description', edit: true, width,
      }],
      columnWidthResizeOption: {
        // default false
        enable: true,
        // column resize min width
        minWidth: 50,
        // sizeChange: ({ column, differWidth, columnWidth }) => {
        //   console.log('width changed', column, differWidth, columnWidth);
        // },
      },
      columnResizeInfo: {
        column: '',
        differWidth: '',
        columnWidth: '',
        tableWidth: '',
      },
      eventCustomOption: {
        bodyCellEvents: ({ row, column, rowIndex }) => ({
          dblclick: (event) => {
            if (['name', 'description'].includes(column.field)) {
              if (!this.readOnly) {
                this.startEditingCell(rowIndex, column.field, row[column.field]);
              }
            } else {
              this.editInput(JSON.parse(JSON.stringify({
                body: row[column.field],
                name: column.field,
                psg: this.inputData[row.index],
                isNewRow: (Object.hasOwn(row, 'isNewRow') && row['isNewRow']),
                type: new RegExp(/\$.+ \((.*)\)$/gm).exec(column.key)[1],
                index: row.index,
              })));
            }
          },
        }),
      },
      // contextmenu body option
      contextmenuBodyOption: {
        /*
                    before contextmenu show.
                    In this function,You can change the `contextmenu` options
                    */
        beforeShow: ({
          isWholeRowSelection,
          selectionRangeKeys,
          selectionRangeIndexes,
        }) => {
          if ((this.operation !== 'create' && this.operation !== 'edit'
            && !this.workflowInfo.permissions.includes('input_edit'))) {
            this.contextmenuBodyOption.contextmenus.forEach((menu, index) => {
              menu.disabled = true;
              if (index === 6) {
                menu.disabled = false;
              }
            });
            return;
          }

          const startIndex = selectionRangeIndexes.startRowIndex;
          const endIndex = selectionRangeIndexes.endRowIndex;

          // Don't show delete option if there is only one input row
          if (this.inputData.filter(r => Object.hasOwn(r, '_paramset')).length <= 1
            && (startIndex === endIndex) && (endIndex === 0)) {
            this.contextmenuBodyOption.contextmenus[4].disabled = true;
          } else {
            this.contextmenuBodyOption.contextmenus[4].disabled = false;
          }

          if (startIndex === endIndex) {
            this.contextmenuBodyOption.contextmenus[0].label = 'Copy Row';
            this.contextmenuBodyOption.contextmenus[4].label = 'Delete Row';
            this.contextmenuBodyOption.contextmenus[6].label = 'Migrate Row';
          } else {
            this.contextmenuBodyOption.contextmenus[0].label = 'Copy Rows';
            this.contextmenuBodyOption.contextmenus[4].label = 'Delete Rows';
            this.contextmenuBodyOption.contextmenus[6].label = 'Migrate Rows';
          }

          let disableCopyMenu = false;
          for (let i = startIndex; i <= endIndex; i++) {
            if (this.inputData[i].name.length === 0) {
              disableCopyMenu = true;
              break;
            }
          }
          if (disableCopyMenu) {
            this.contextmenuBodyOption.contextmenus[0].disabled = true;
            this.contextmenuBodyOption.contextmenus[6].disabled = true;
          } else {
            this.contextmenuBodyOption.contextmenus[0].disabled = false;
            this.contextmenuBodyOption.contextmenus[6].disabled = false;
          }

          for (let i = startIndex; i <= endIndex; i++) {
            if (this.inputData[i].name.length === 0 || !this.inputData[i].name) {
              this.contextmenuBodyOption.contextmenus[6].disabled = true;
              break;
            }
          }
        },
        // after menu click
        afterMenuClick: ({ type, selectionRangeKeys, selectionRangeIndexes }) => {
          const startIndex = selectionRangeIndexes.startRowIndex;
          const endIndex = selectionRangeIndexes.endRowIndex;
          if (type === 'insert-row') {
            this.insertNewRow();
          }
          if (type === 'copy-row') {
            this.currentRowToCopyIndex.start = selectionRangeIndexes.startRowIndex;
            this.currentRowToCopyIndex.end = selectionRangeIndexes.endRowIndex;
            this.showCopyDialog = true;
          }
          if (type === 'delete-row') {
            this.showRunningJobMsgDel = false;
            if (this.inputData.slice(startIndex, endIndex + 1).filter(r => Object.hasOwn(r, '_paramset')).length
              === this.inputData.filter(r => Object.hasOwn(r, '_paramset')).length) {
              selectionRangeIndexes.startRowIndex++;
            }
            this.currentRowToDeleteIndex.start = selectionRangeIndexes.startRowIndex;
            this.currentRowToDeleteIndex.end = selectionRangeIndexes.endRowIndex;
            const runningJobsSet = new Set(this.workflowData.workFlowJobModel.runningJobs);
            const currentSelectedRowSet = new Set(this.manager.rawPsgModel
              .slice(selectionRangeIndexes.startRowIndex, selectionRangeIndexes.endRowIndex + 1).map(r => r.id));
            if (runningJobsSet.intersection(currentSelectedRowSet).size > 0) {
              this.showRunningJobMsgDel = true;
            }

            this.showDeleteDialog = true;
          }
          if (type === 'migrate-row') {
            this.selectedInputsForMigration = this.inputData
              .slice(selectionRangeIndexes.startRowIndex, selectionRangeIndexes.endRowIndex + 1)
              .map(input => input.name).filter(inputName => !!inputName);
            this.showInputSetMigrationDialog = true;
          }
        },

        // contextmenus
        contextmenus: [
          {
            type: 'copy-row',
            label: 'Copy Row',
            disabled: true,
          },
          {
            type: 'SEPARATOR',
          },
          {
            type: 'insert-row',
            label: 'Insert Row',
          },
          {
            type: 'SEPARATOR',
          },
          {
            type: 'delete-row',
            label: 'Delete Row',
          },
          {
            type: 'SEPARATOR',
          },
          {
            type: 'migrate-row',
            label: 'Migrate Row',
          },
        ],
      },
      showInputLoader: false,
      headers: [
        {
          text: 'Name',
          fieldName: 'name',
          style: {
            width: '150px',
          },
        },
        {
          text: 'Description',
          fieldName: 'description',
          style: {
            width: '150px',
          },
        },
      ],
      paramHeaders: [],
      inputData: [],
      tableData: [],
      inputToggle: false,
      currentInputToEdit: {
        name: '',
        body: {},
      },
      allUserDataSources: {},
      userFeeds: [],
      dataSetForSources: [],
      selectedSource: [],
      selectedProvider: '',
      dataSetForFeed: [],
      selectedFeed: [],
      currentFeedDetails: {},
      dataSetForColumns: [],
      disableColumns: false,
      selectedColumns: [],
      currentFeedRoots: [],
      currentInputIsForwardCurve: false,
      selectedKeys: {},
      selectedRoots: [],
      currentInputIsVariableType: false,
      variableDataType: '',
      variableValue: '',
      minMaxDates: {
        min: new Date(1970, 0, 1),
        max: new Date(2050, 11, 31),
      },
      disableCopyButton: true,
      rawPsgModel: [],
      psgToAdd: [],
      modifiedParameters: [],
      deletedParameters: {
        psgIds: [],
      },
      showCopyDialog: false,
      showDeleteDialog: false,
      showNewRowDialog: false,
      showMainLoader: false,
      workflowData: {},
      newInputRows: [],
      newRowName: '',
      colTypes: {},
      isNewRowAdded: false,
      showValidationFailedDialog: false,
      validationFailureMessage: '',
      showChangeDialog: false,
      currentRenamedRow: {
        value: '', type: '', index: '',
      },
      renamedRows: [],
      showRenameDialog: false,
      manager: {},
      searchData: {},
      filteredSearchData: {},
      tempSearchData: {},
      searchString: '',
      searchFilters: [],
      searchFocused: false,
      expireSet: false,
      expireTime: '',
      enableCorrection: false,
      // correctionData: {
      //   lookBackFromDays: 0,
      //   lookBackToDays: 0,
      //   lookBackFromInsertionDays: 0,
      //   timeZone: null,
      // },
      timeZoneDataset: timeZone.map(tz => ({ text: tz, value: tz })),
      readOnly: false,
      currentFeedKeys: [],
      dataSetCorrectionInfo: {},
      isDataNode: false,
      workflowInfo: {},
      resizingColumn: null,
      startX: 0,
      toggleaddNewKey: false,
      notNewKey: true,
      addedNewKey: '',
      toggleRemoveAddNewKey: false,
      inputFeedNotAvailable: false,
      errorMessageForFeed: [],
      paramSetGroup: {},
      parameterSetData: {},
      varValueError: false,
      showInputSetMigrationDialog: false,
      selectedInputsForMigration: [],
      wasWFRestarted: false,
      showRunningJobMsgDel: false,
      deletedRunIds: [],
      parameterSetStatus: {},
    };
  },
  computed: {
    ...mapGetters('feedModule', ['getFeedList', 'getFeedDetails', 'getAllFeedRoots', 'getErrorMessage']),
    ...mapGetters('workflowModule', ['getParamStatus', 'getCreateNewWorkflowDetails',
      'getEditableWorkflowDetails', 'getCurrentWorkflowManager']),
    selectionStatus() {
      // filter your rows by the checked property
      const filtered = this.inputData.filter(row => row.visible).filter(row => row.checked === true);
      // isIndeterminate = there are more than one rows checked but not all
      // isAllSelected = all rows are checked
      return {
        isIndeterminate: filtered.length > 0 && filtered.length < this.inputData.length,
        isAllSelected: filtered.length === this.inputData.length,
      };
    },
    getDeletedRowNames() {
      return this.rawPsgModel.filter(psg => this.deletedParameters.psgIds.includes(psg.id));
    },
    getModifiedRowNames() {
      const rowNames = [];
      const modifiedIds = this.modifiedParameters.map(param => param.id);

      this.rawPsgModel.forEach((psg) => {
        psg.parameterModels.forEach((param) => {
          if (modifiedIds.includes(param.id)) {
            rowNames.push(psg.name);
          }
        });
      });
      return [...new Set(rowNames)];
    },
    newRowButtonDisabled() {
      return this.newRowName.length === 0 || this.inputData.map(row => row.name).includes(this.newRowName.trim());
    },
    copyRowButtonDisabled() {
      const copiedNames = this.inputData.slice(this.currentRowToCopyIndex.start, this.currentRowToCopyIndex.end + 1)
        .map(row => row.copiedRowName.trim());
      const rowNames = this.inputData.map(row => row.name);
      return copiedNames.some(row => rowNames.includes(row)) || new Set(copiedNames).size !== copiedNames.length
        || copiedNames.some(r => r.length > 50);
    },
    deleteRowButtonDisabled() {
      return this.workflowInfo.wasWFRestarted;
    },
    showSubmitButton() {
      if (this.psgToAdd.length > 0) {
        return true;
      }
      if (this.modifiedParameters.length > 0) {
        return true;
      }
      if (this.deletedParameters.psgIds.length > 0) {
        return true;
      }
      if (this.renamedRows.length > 0) {
        return true;
      }
      return this.isNewRowAdded;
    },
    showSearchResults() {
      return this.searchFocused && Object.keys(this.tempSearchData).length > 0;
    },
  },
  watch: {
    inputData: {
      handler(value) {
        if (value.length > 25) {
          this.virtualScrollOption.enable = true;
        } else {
          this.virtualScrollOption.enable = false;
        }
        this.manager.inputData = value;
        value.filter(row => row._isModified).forEach((row) => {
          this.generateParamsetForRow(row);
          this.generateUpdateModel();
          row._isModified = false;
        });
        this.tableData = this.inputData.filter(row => row.visible);
        if (Object.hasOwn(this.parameterSetData, 'parameterSetModels')) {
          this.parameterSetData.parameterSetModels.splice(0, this.parameterSetData.parameterSetModels.length);
          this.inputData.filter(row => row.name.length > 0).forEach((row) => {
            if (!Object.hasOwn(row, '_currentServerModel')) {
              this.generateParamsetForRow(row);
            }
            row._currentServerModel.status = this.parameterSetStatus[row._currentServerModel.id].status;
            row._currentServerModel.fetching = this.parameterSetStatus[row._currentServerModel.id].fetching;
            this.parameterSetData.parameterSetModels.push(row._currentServerModel);
          });
        }
      },
      deep: true,
    },
    showSubmitButton(val) {
      if (val) {
        this.$emit('diagramChanged');
      }
    },
    psgToAdd: {
      handler(val, oldVal) {
        this.manager._priv.updateParameterSetData.add = val;
      },
    },
    modifiedParameters: {
      handler(val, oldVal) {
        this.manager._priv.updateParameterSetData.update = val;
      },
    },
    deletedParameters: {
      handler(val, oldVal) {
        this.manager._priv.updateParameterSetData.delete = val;
      },
    },
    renamedRows: {
      handler(val, oldVal) {
        this.manager._priv.updateParameterSetData.psgToRename = val;
      },
    },
    searchString(value) {
      if (value.length === 0) {
        this.tempSearchData = {};
        return;
      }
      const keywords = ['cols', 'key', 'roots', 'feed', 'source', 'value'];
      const typedKeywords = keywords.filter(keyword => keyword.startsWith(value.toLowerCase()));
      if (typedKeywords.length > 0) {
        this.buildSearchData();
        typedKeywords.forEach((keyword) => {
          this.tempSearchData = _.pick(this.filteredSearchData, keyword);
        });
      } else {
        this.tempSearchData = {};
      }
    },
    searchFilters: {
      handler(val, oldVal) {
        if (this.searchFilters.length === 0) {
          this.inputData.forEach((row) => {
            row.visible = true;
          });
        } else {
          this.inputData.forEach((row) => {
            row.visible = false;
            row.checked = false;
          });
          this.searchFilters.forEach((filter) => {
            this.buildSearchData();
            if (filter.isSimpleTag) {
              this.inputData.forEach((row) => {
                const rowVars = Object.keys(row).filter(key => typeof row[key] === 'object'
                || ['description', 'name'].includes(key)).map(key => row[key]);
                if (JSON.stringify(rowVars).toLowerCase().includes(filter.value.toLowerCase())) {
                  row.visible = true;
                }
              });
            } else {
              const [type] = Object.keys(filter.value);
              this.inputData.forEach((row) => {
                const rowData = Object.keys(row).filter(key => typeof row[key] === 'object').map(key => row[key]);
                row.visible = rowData.some((variable) => {
                  if (type === 'cols' && Object.keys(variable).includes(type)) {
                    return _.intersection(filter.value.cols, variable.cols).length > 0;
                  }
                  if (['feed', 'source'].includes(type) && Object.keys(variable).includes(type)) {
                    return filter.value[type].some(e => variable[type].toLowerCase().startsWith(e.toLowerCase()));
                  }
                  if (type === 'key' && Object.keys(variable).includes('key')) {
                    return _.intersection(Object.keys(variable.key), filter.value[type]).length > 0;
                  }
                  if (type === 'roots' && Object.keys(variable).includes('key')) {
                    return _.intersection(variable.key, filter.value[type]).length > 0;
                  }
                  if (type === 'value' && Object.keys(variable).includes('value')) {
                    return `${variable.value}` === `${filter.value.value}`;
                  }
                  return false;
                });
              });
            }
          });
        }
      },
    },
  },
  beforeMount() {
    this.workflowInfo = this.getEditableWorkflowDetails.workflowUI;
    this.parameterSetData = this.getEditableWorkflowDetails.paramSet
      .workFlowJobModel.parameterSetGroupModel;
    this.parameterSetData.parameterSetModels.forEach((psm) => {
      this.parameterSetStatus[psm.id] = {
        status: psm.status,
        fetching: psm.fetching,
      };
    });
    if (this.operation === 'view' && !this.workflowInfo.permissions.includes('input_edit')) {
      this.readOnly = true;
    }
    this.cellAutofillOption.directionY = this.operation === 'edit' || this.operation === 'create'
      || this.workflowInfo.permissions.includes('input_edit');
    this.workflowData = JSON.parse(JSON.stringify(this.getParamStatus(this.workflowId)));
    this.manager = this.getCurrentWorkflowManager;
    this.paramSetGroup = this.manager._priv.paramSetGroup;
    this.inputData = this.manager.inputData;
    this.rawPsgModel = this.manager.rawPsgModel;
    let updateBody = this.manager._priv.updateParameterSetData;
    if (!updateBody) {
      updateBody = {
        add: [], update: [], delete: { psgIds: [], paramIds: [] }, psgToRename: [],
      };
      this.manager._priv.updateParameterSetData = updateBody;
    } else {
      this.psgToAdd = updateBody.add;
      this.modifiedParameters = updateBody.update;
      this.deletedParameters = updateBody.delete;
      this.renamedRows = updateBody.psgToRename;
    }
    const alreadyAddedPsgIndex = {};
    this.psgToAdd.forEach((psg, index) => {
      alreadyAddedPsgIndex[psg.name] = index;
    });

    this.inputData.forEach((row, index) => {
      row.visible = true;
      row.index = index;
      row.prevName = row.name;
      row.prevDescription = row.description;
      // if (Object.hasOwn(alreadyAddedPsgIndex, row.name)) {
      //   row.isNewRow = true;
      // }
      if (this.paramSetGroup && Object.hasOwn(this.paramSetGroup, '_paramsets')) {
        const pIndex = this.paramSetGroup._paramsets.findIndex(paramset => paramset._name === row.name);
        if (pIndex >= 0) {
          row['_paramset'] = this.paramSetGroup._paramsets[pIndex];
          row._prevServerModel = _paramSetToServerModel(row._paramset);
          row._prevServerModel.id = row._paramset._id;
          const psWithIds = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels
            .find(ps => `${ps.id}` === `${row._paramset._id}`);
          const paramMap = {};
          psWithIds.parameterModels.forEach((element) => {
            paramMap[element.propKey] = element;
          });
          row._prevServerModel.parameterModels.forEach((element) => {
            if (paramMap[element.propKey] && Object.hasOwn(paramMap[element.propKey], 'id')) {
              element.id = paramMap[element.propKey].id;
            }
          });
          this.generateCurrentServerModelForRow(row);
          const renamedIndex = this.renamedRows
            .findIndex(r => (Object.hasOwn(row, '_currentServerModel') ? r.id === row._currentServerModel.id : false));
          if (renamedIndex >= 0) {
            row.name = this.renamedRows[renamedIndex].name;
            row.description = this.renamedRows[renamedIndex].description;
          }
        }
        row._isModified = false;
      }
    });

    this.manager.inputData = this.inputData;
    if (this.inputData.filter(row => row.name.length > 0).length >= 10
      && this.inputData[this.inputData.length - 1].name.length > 0) {
      this.addNewRow(true);
    }
    for (let i = this.inputData.length; i < 10; i++) {
      this.addNewRow(true);
    }

    this.manager._priv.datasets.forEach((ds) => {
      this.dataSetCorrectionInfo[ds._name] = ds._bbl._data;
    });
    this.paramHeaders = this.manager.paramHeaders;
    this.paramHeaders.forEach((header) => {
      this.columns.push({ prop: header.fieldName || new RegExp(/\$(.+) \(.*$/gm).exec(header.text)[1], name: header.text });
      this.cols.push({
        field: header.fieldName || new RegExp(/\$(.+) \(.*$/gm).exec(header.text)[1],
        title: header.text,
        key: header.text,
        align: 'left',
        width,
        renderBodyCell: ({ row, column, rowIndex }, h) => (
            <span>
              {this.$options.filters.showColumnData(row[column.field]).replaceAll('<br>', '\n')}
            </span>
        ),
      });
    });
    this.headers = this.headers.concat(this.paramHeaders);
    this.colTypes = this.manager.colTypes;
    if (!Object.hasOwn(this.manager, 'rawPsgModel') || !this.manager.rawPsgModel) {
      this.manager.rawPsgModel = this.manager._priv.paramSetGroup.parameterSets().map(_paramSetToServerModel);
    }
    this.buildSearchData();
    this.tableData = this.inputData;
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('feedModule', ['getUserDatasources', 'getUserFeeds', 'getFeedDetailsByName', 'getFeedRoots']),
    ...mapActions('workflowModule', ['getUserWorkflows', 'getWorkflowStatus', 'setParamSetWFId']),
    startEditingCell(rowKey, colKey, defaultValue) {
      this.$refs['tableRef'].startEditingCell({ rowKey, colKey, defaultValue });
    },
    init() {
      if (this.workflowInfo.wasWFRestarted) {
        this.wasWFRestarted = this.workflowInfo.wasWFRestarted;
      }
    },
    restartCompleted() {
      this.workflowData = JSON.parse(JSON.stringify(this.getParamStatus(this.workflowId)));
      this.showDeleteDialog = false;
    },
    generateCurrentServerModelForRow(row) {
      const keys = Object.keys(row).filter(key => !key.startsWith('_')).filter(key => typeof row[key] === 'object');

      const parameterModels = [];

      keys.forEach((key) => {
        const temp = row[key];
        if (temp.type === 'timeSeries' || temp.type === 'forwardCurve') {
          parameterModels.push({
            propKey: `udef.ds.${key}.cols`,
            propValue: JSON.stringify(row[key].cols),
          });
          parameterModels.push({
            propKey: `udef.ds.${key}.feed`,
            propValue: row[key].feed,
          });
          parameterModels.push({
            propKey: `udef.ds.${key}.key`,
            propValue: JSON.stringify(row[key].key),
          });
          parameterModels.push({
            propKey: `udef.ds.${key}.prov`,
            propValue: row[key].prov,
          });
          parameterModels.push({
            propKey: `udef.ds.${key}.source`,
            propValue: row[key].source,
          });
        } else {
          parameterModels.push({
            propKey: `udef.var.${key}`,
            propValue: row[key].value,
          });
        }
      });

      if (Object.hasOwn(row, '_prevServerModel')) {
        row._currentServerModel = JSON.parse(JSON.stringify(row._prevServerModel));
        row._currentServerModel.parameterModels = _.unionBy(parameterModels, row._currentServerModel.parameterModels, 'propKey');
        const prevParamMap = {};
        row._prevServerModel.parameterModels.forEach((p) => {
          prevParamMap[p.propKey] = p;
        });
        row._currentServerModel.parameterModels.forEach((p) => {
          if (Object.hasOwn(prevParamMap, p.propKey) && Object.hasOwn(prevParamMap[p.propKey], 'id')) {
            p.id = prevParamMap[p.propKey].id;
          }
        });
      } else {
        row._currentServerModel = {
          description: row.description,
          name: row.name,
          parameterModels,
        };
      }
    },
    // set range cell selection
    setRangeCellSelection(startRow, endRow, startCol, endCol) {
      this.$refs['tableRef'].setRangeCellSelection({
        startRowKey: startRow,
        startColKey: startCol,
        endRowKey: endRow,
        endColKey: endCol,
        isScrollToStartCell: true,
      });
    },
    insertNewRow() {
      this.addNewRow(true);
    },
    isParamEmpty(param) {
      if (Object.hasOwn(param, 'isValueType') && param.isValueType) {
        return param.value.length === 0;
      } if (Object.hasOwn(param, 'type') && ['timeSeries', 'forwardCurve'].includes(param.type)) {
        return (JSON.stringify(param.cols).length === 0 || param.feed.length === 0 || param.prov.length === 0
          || JSON.stringify(param.key).length === 0 || param.source.length === 0);
      }
      return true;
    },
    checkIfRowhasData(row) {
      const rowVars = Object.keys(row).filter(key => !key.startsWith('_') && typeof row[key] === 'object')
        .filter(key => !['isNewRow', 'index'].includes(key)).map(key => row[key]);
      return rowVars.some(v => !this.isParamEmpty(v));
    },
    generateParamsetForRow(row) {
      const params = {};
      Object.keys(row).filter(key => !key.startsWith('_')).filter(key => !['isNewRow', 'index'].includes(key)).map((key) => {
        const obj = {};
        obj[key] = row[key];
        return obj;
      })
        .forEach((param) => {
          const key = Object.keys(param)[0];
          if (key === 'name') {
            params._name = param.name;
          } else if (key === 'description') {
            params._descr = param.description;
          } else if (typeof param[key] === 'object' && !param[key].isValueType && !this.isParamEmpty(param[key])) {
            if (!Object.hasOwn(params, '_params')) {
              params._params = {};
            }
            params._params[`udef.ds.${key}.cols`] = JSON.stringify(param[key].cols);
            params._params[`udef.ds.${key}.feed`] = param[key].feed;
            params._params[`udef.ds.${key}.key`] = JSON.stringify(param[key].key);
            params._params[`udef.ds.${key}.prov`] = param[key].prov;
            params._params[`udef.ds.${key}.source`] = param[key].source;
          } else if (typeof param[key] === 'object' && param[key].isValueType && !this.isParamEmpty(param[key])) {
            if (!Object.hasOwn(params, '_params')) {
              params._params = {};
            }
            params._params[`udef.var.${key}`] = param[key].value;
          }
        });
      row._paramset = new ParameterSet(
        Object.hasOwn(row, '_paramset') ? row._paramset._id : -1,
        Object.hasOwn(row, '_paramset') ? row._paramset._uuid : '',
        row.prevName,
        Object.hasOwn(row, '_paramset') ? _.merge({}, row._paramset._params, params._params) : params._params,
        row.prevDescription,
        true,
      );
      this.generateCurrentServerModelForRow(row);
    },
    generateUpdateModel() {
      const alreadyAddedPsgIndex = {};
      this.psgToAdd.forEach((psg, index) => {
        alreadyAddedPsgIndex[psg.name] = index;
      });
      const existingModifiedParam = {};
      this.modifiedParameters.forEach((param, index) => {
        existingModifiedParam[param.id] = index;
      });
      const existingRenamedRows = {};
      this.renamedRows.forEach((row, index) => {
        existingRenamedRows[row.id] = index;
      });
      this.inputData.forEach((row) => {
        if (row.isNewRow && row._isModified && row.name.length > 0 && Object.hasOwn(row, '_currentServerModel')) {
          if (Object.hasOwn(alreadyAddedPsgIndex, row.name)) {
            this.psgToAdd[alreadyAddedPsgIndex[row.name]] = row._currentServerModel;
          } else if (Object.hasOwn(row, 'prevName') && Object.hasOwn(alreadyAddedPsgIndex, row.prevName)) {
            this.psgToAdd[alreadyAddedPsgIndex[row.prevName]] = row._currentServerModel;
          } else {
            this.psgToAdd.push(row._currentServerModel);
          }
          row._isModified = false;
        } else if (row._isModified && row.name.length > 0 && Object.hasOwn(row, '_currentServerModel')
          && Object.hasOwn(row, '_prevServerModel')) {
          const updatedParams = _.differenceBy(row._currentServerModel.parameterModels,
            row._prevServerModel.parameterModels, 'propValue');

          updatedParams.forEach((param) => {
            const temp = row._prevServerModel.parameterModels.find(p => param.propKey === p.propKey);
            if (temp && Object.hasOwn(temp, 'id')) {
              param.id = temp.id;
            }
            const psgToAddIndex = this.psgToAdd.findIndex(r => r.name === row.name);
            if (Object.hasOwn(existingModifiedParam, param.id)) {
              this.modifiedParameters[existingModifiedParam[param.id]] = param;
            } else if (psgToAddIndex >= 0 && this.psgToAdd[psgToAddIndex].parameterModels
              .find(p => p.propKey === param.propKey)) {
              const alreadyAddedParamToUpdate = this.psgToAdd[psgToAddIndex].parameterModels
                .find(p => p.propKey === param.propKey);
              if (alreadyAddedParamToUpdate) {
                alreadyAddedParamToUpdate.propValue = param.propValue;
              }
            } else if (Object.hasOwn(param, 'id')) {
              this.modifiedParameters.push(param);
            }
          });

          if (row.name !== row.prevName || row.description !== row.prevDescription) {
            const rowId = row._prevServerModel.id;
            const renamedObj = {
              id: rowId,
              name: row.name,
              description: row.description,
            };
            if (Object.hasOwn(existingRenamedRows, rowId)) {
              this.renamedRows[existingRenamedRows[rowId]] = renamedObj;
            } else {
              this.renamedRows.push(renamedObj);
            }
          }
        }
      });
    },
    deleteItem(selectedItem) {
      this.searchFilters.splice(this.searchFilters.findIndex(item => item.value === selectedItem.value), 1);
    },
    clearAll() {
      this.searchFilters = [];
    },
    addFilterTag(property, field) {
      const temp = {
        text: `${property} = ${field}`,
        value: {},
        isSimpleTag: false,
      };
      temp.value[property] = [field];
      this.$set(this.searchFilters, this.searchFilters.length, temp);
      this.searchString = '';
      this.searchFocused = false;
    },
    addSimpleStringFilterTag() {
      const temp = {
        text: `${this.searchString}`,
        value: `${this.searchString}`,
        isSimpleTag: true,
      };
      this.$set(this.searchFilters, this.searchFilters.length, temp);
      this.searchString = '';
    },
    buildSearchData() {
      this.searchData = {};
      this.inputData.filter(row => row.visible).forEach((row) => {
        const searchableFields = ['cols', 'feed', 'key', 'source', 'value'];
        const rowData = Object.keys(row).filter(key => typeof row[key] === 'object').map(key => row[key]);
        rowData.forEach((r) => {
          Object.keys(r).forEach((field) => {
            if (searchableFields.includes(field)) {
              if (field === 'cols') {
                if (Object.hasOwn(this.searchData, field)) {
                  this.searchData[field].add(...r[field]);
                } else {
                  this.searchData[field] = new Set();
                  this.searchData[field].add(...r[field]);
                }
              } else if (field === 'key') {
                const keyData = r[field];
                if (!(keyData instanceof Array)) {
                  Object.keys(keyData).forEach((feedKey) => {
                    if (!Object.hasOwn(this.searchData, 'key')) {
                      this.searchData['key'] = {};
                    }
                    if (!Object.hasOwn(this.searchData.key, feedKey)) {
                      this.searchData.key[feedKey] = new Set();
                    }
                    this.searchData.key[feedKey].add(keyData[feedKey]);
                  });
                } else {
                  if (!Object.hasOwn(this.searchData, 'roots')) {
                    this.searchData['roots'] = new Set();
                  }
                  this.searchData['roots'].add(...r[field]);
                }
              } else {
                if (!Object.hasOwn(this.searchData, field)) {
                  this.searchData[field] = new Set();
                }
                this.searchData[field].add(r[field]);
              }
            }
          });
        });
      });
      this.filteredSearchData = { ...this.searchData };
      // this.tempSearchData = { ...this.filteredSearchData };
    },
    handleRowSelectEvent(rowIndex, isChecked) {
      // set the checked property in the row data to the checked value of the checkbox
      this.$set(this.inputData[rowIndex], 'checked', isChecked);
      this.disableCopyButton = false;
    },
    handleMultiSelection(event) {
      // set all rows checked property to the checked value of the header checkbox
      this.inputData.forEach((row, i) => {
        if (row.visible) {
          row.checked = event.target.checked;
        }
      });
      this.disableCopyButton = false;
    },
    showRenamedRow(data) {
      this.currentRenamedRow = data;
      if (this.operation !== 'view') {
        this.showRenameDialog = true;
      }
    },
    saveRenamedRow(isDisabled) {
      if (isDisabled) {
        return;
      }
      const { value, type, index } = this.currentRenamedRow;
      const { rowName } = this.inputData[index];
      const { id, name, description } = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels
        .find(psm => psm.name === rowName);

      if (type === 'Name') {
        if (this.renamedRows.find(row => row.id === id)) {
          this.renamedRows.find(row => row.id === id).name = value;
        } else {
          this.renamedRows.push({ id, name: value, description });
        }
        this.inputData[index].name = value;
      } else {
        if (this.renamedRows.find(row => row.id === id)) {
          this.renamedRows.find(row => row.id === id).description = value;
        } else {
          this.renamedRows.push({ id, name, description: value });
        }
        this.inputData[index].description = value;
      }
      this.showRenameDialog = false;
    },
    sourceChangeListener() {
      if (this.selectedSource.length === 0) {
        return;
      }
      this.inputFeedNotAvailable = false;
      // change feed
      this.dataSetForFeed = this.allUserDataSources[this.selectedSource].feeds.map((f) => {
        f.text = f.name;
        f.value = f.name;
        return f;
      });
      this.dataSetForFeed.unshift(defaultOption);
      this.selectedFeed = [defaultOption.text];
      this.feedChangeListener();
    },
    feedChangeListener() {
      if (this.selectedFeed.length === 0) {
        return;
      }
      // clear columns
      this.$set(this, 'selectedColumns', []);
      this.dataSetForColumns = [];
      this.disableColumns = true;
      // clear keys
      this.selectedKeys = {};
      // clear roots
      this.selectedRoots = [];
      // fetch feed details
      if (this.selectedFeed[0] !== defaultOption.text) {
        this.fetchFeedDetails(this.selectedFeed, []);
      }
    },
    getKeys(value) {
      let keyStr = '';
      if (!value) {
        return keyStr;
      }
      Object.keys(value).forEach((key, index) => {
        if (index === 0) {
          keyStr += value[key];
        } else {
          keyStr += `, ${value[key]}`;
        }
      });
      return keyStr;
    },
    async fetchDatasourceForUser(feed, cols, source) {
      if (this.userFeeds.length === 0) {
        try {
          await this.getUserFeeds();
          await this.extractDataSourcesFromFeeds(feed, cols);
          if (source && source.length > 0) {
            if (this.inputFeedNotAvailable) {
              this.dataSetForSources.unshift(defaultOption);
              this.dataSetForFeed.push({
                text: feed,
                value: feed,
              });
              this.selectedSource = 'default';
            } else {
              this.selectedSource[0] = source;
              this.dataSetForFeed = this.allUserDataSources[source].feeds.map((f) => {
                f.text = f.name;
                f.value = f.name;
                return f;
              });
            }
          }
          this.dataSetForFeed.unshift(defaultOption);
          this.$nextTick(() => {
            this.selectedFeed = [feed];
          });
        } catch {
          this.showInputLoader = false;
          this.showMainLoader = false;
        }
      } else {
        await this.extractDataSourcesFromFeeds(feed, cols);
        if (source && source.length > 0) {
          if (this.inputFeedNotAvailable) {
            this.dataSetForSources.unshift(defaultOption);
            this.dataSetForFeed.push({
              text: feed,
              value: feed,
            });
            if (this.dataSetForSources.find(s => s.value === source)) {
              this.selectedSource = source;
            } else {
              this.selectedSource = 'default';
            }
          } else {
            this.selectedSource = source;
            this.dataSetForFeed = this.allUserDataSources[source].feeds.map((f) => {
              f.text = f.name;
              f.value = f.name;
              return f;
            });
          }
          this.$nextTick(() => {
            this.selectedFeed = [feed];
          });
        }
      }
    },
    parseAndFormatValueTypeInput(args) {
      this.isDataNode = false;
      this.currentInputIsVariableType = true;
      this.currentInputToEdit = args;
      const variableTypeInfo = this.determineVariableType(args.body.value);
      this.variableDataType = variableTypeInfo.type;
      this.variableValue = variableTypeInfo.value;
      this.showInputLoader = false;
      this.showMainLoader = false;
    },
    determineVariableType(value) {
      let varType = null;
      let varValue = null;
      try {
        varType = typeof JSON.parse(value);
        varValue = JSON.stringify(JSON.parse(value));
      } catch {
        if (/\d{4}-\d{2}-\d{2}/gm.test(value)) {
          varValue = new Date(value);
          // eslint-disable-next-line eqeqeq
          if (varValue == 'Invalid Date') {
            varType = 'string';
            varValue = value;
          } else {
            varType = 'date';
          }
        } else {
          varType = 'string';
        }
      }
      return { type: varType, value: varValue };
    },
    async parseAndFormatFeedTypeInput(args) {
      this.currentInputIsVariableType = false;
      if (!args.body.isForwardCurve) {
        this.selectedKeys = args.body.key;
      } else {
        this.selectedRoots = args.body.key;
      }
      const {
        feed, source, cols, isForwardCurve,
      } = args.body;
      this.currentInputIsForwardCurve = isForwardCurve;
      this.inputToggle = true;
      this.currentInputToEdit = args;
      this.userFeeds = this.getFeedList;

      if (args.type === 'Data') {
        this.isDataNode = true;
        this.expireSet = this.dataSetCorrectionInfo[args.name].expire_set
          ? this.dataSetCorrectionInfo[args.name].expire_set : false;
        this.expireTime = this.dataSetCorrectionInfo[args.name].expire_time
          ? this.dataSetCorrectionInfo[args.name].expire_time : '12:00';
        this.enableCorrection = this.dataSetCorrectionInfo[args.name].enable_correction
          ? this.dataSetCorrectionInfo[args.name].enable_correction : false;
        // this.correctionData = this.dataSetCorrectionInfo[args.name].correction_data || {
        //   lookBackFromDays: 0,
        //   lookBackToDays: 0,
        //   lookBackFromInsertionDays: 0,
        //   timeZone: null,
        // };
      } else {
        this.isDataNode = false;
      }

      await this.fetchDatasourceForUser(feed, cols, source);
    },
    cancelEditPopup() {
      this.inputToggle = !this.inputToggle;
      this.resetEditPopup();
    },
    resetEditPopup() {
      this.$set(this, 'selectedColumns', []);
      this.selectedFeed = [];
      this.selectedKeys = {};
      this.selectedRoots = [];
      this.selectedSource = [];
      this.varValueError = false;
    },
    editInput(args) {
      this.resetEditPopup();
      this.inputToggle = true;
      this.showInputLoader = true;
      if (this.isParamEmpty(args.body)) {
        this.parseAndFormatNewRowParameter(args);
      } else if (args.body.isValueType) {
        this.parseAndFormatValueTypeInput(args);
      } else {
        this.parseAndFormatFeedTypeInput(args);
      }
    },
    parseAndFormatNewRowParameter(args) {
      this.variableDataType = this.colTypes[args.name];
      if (Object.hasOwn(args.body, 'value')) {
        this.currentInputIsVariableType = true;
        this.isDataNode = false;
      } else {
        this.currentInputIsVariableType = false;
        this.isDataNode = true;
        const {
          feed, source, cols, isForwardCurve,
        } = args.body;
        this.currentInputIsForwardCurve = isForwardCurve;
        this.fetchDatasourceForUser(feed, cols, source);
      }
      this.currentInputToEdit = args;
      this.showInputLoader = false;
      this.showMainLoader = false;
    },
    sortDataSources(a, b) {
      const fa = a.text.toLowerCase();
      const fb = b.text.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    async extractDataSourcesFromFeeds(feedname, cols) {
      const datasourceSet = {};
      this.getFeedList.forEach((f) => {
        if (Object.hasOwn(datasourceSet, f.dataSource)) {
          datasourceSet[f.dataSource]['feeds'].push(f);
        } else {
          datasourceSet[f.dataSource] = {
            text: f.dataSource,
            value: f.dataSource,
            feeds: [f],
          };
        }
      });
      this.allUserDataSources = datasourceSet;
      this.dataSetForSources = Object.values(datasourceSet).sort(this.sortDataSources);
      await this.fetchFeedDetails(feedname, cols);
    },
    async fetchFeedDetails(feedname, cols) {
      try {
        if (this.currentFeedDetails || !this.getFeedDetails || this.getFeedDetails.name !== feedname) {
          await this.getFeedDetailsByName(feedname);
          if (this.getFeedDetails.name !== feedname) {
            throw new Error('Feed not found');
          }
          this.currentFeedDetails = this.getFeedDetails;
          this.selectedProvider = this.currentFeedDetails.provider;
        }
        this.extractFeedColumns(cols);

        if (!this.currentInputIsForwardCurve) {
          this.showInputLoader = false;
          this.showMainLoader = false;
        } else if (Object.hasOwn(this.getAllFeedRoots, feedname)) {
          this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));
          this.showInputLoader = false;
          this.showMainLoader = false;
          if (this.currentFeedRoots.length > 0) {
            let flag = 0;
            for (let i = 0; i < this.currentFeedRoots.length; i++) {
              if (this.currentFeedRoots.value === this.currentInputToEdit.body.key[0]) {
                flag = 1;
              }
            }
            if (flag === 0 && ['Publish', 'Save', 'publish', 'save'].includes(this.currentInputToEdit.type)) {
              this.currentFeedRoots.push({
                text: `${this.currentInputToEdit.body.key[0]} -- [new]`,
                value: this.currentInputToEdit.body.key[0],
              });
            }
          } else {
            this.notNewKey = true;
          }
        } else {
          await this.getFeedRoots(feedname);
          this.currentFeedRoots = this.getAllFeedRoots[feedname].map(root => ({ text: root['root'], value: root['root'] }));
          this.showInputLoader = false;
          this.showMainLoader = false;
          if (this.currentFeedRoots.length > 0) {
            let flag = 0;
            for (let i = 0; i < this.currentFeedRoots.length; i++) {
              if (this.currentFeedRoots.value === this.currentInputToEdit.body.key[0]) {
                flag = 1;
              }
            }
            if (flag === 0 && ['Publish', 'Save', 'publish', 'save'].includes(this.currentInputToEdit.type)) {
              this.currentFeedRoots.push({
                text: `${this.currentInputToEdit.body.key[0]} -- [new]`,
                value: this.currentInputToEdit.body.key[0],
              });
            }
          } else {
            this.notNewKey = true;
          }
        }

        this.currentFeedKeys = [];
        for (let i = 0; i < this.currentFeedDetails.fields.length; i++) {
          const element = this.currentFeedDetails.fields[i];
          if (element.type.toLowerCase() === 'k') {
            this.currentFeedKeys.push(element.fieldName);
          }
        }
        this.inputFeedNotAvailable = false;
      } catch (error) {
        this.inputFeedNotAvailable = true;
        this.errorMessageForFeed[0] = this.getErrorMessage.replace(feedname, 'feed');

        cols.forEach((col) => {
          this.dataSetForColumns.push({
            text: col,
            value: col,
          });
        });
        this.$set(this, 'selectedColumns', cols);
        this.showInputLoader = false;
        this.showMainLoader = false;
      }
    },
    addNewKey() {
      this.notNewKey = false;
    },
    removeAddNewKey() {
      this.notNewKey = true;
    },
    addNewLabel() {
      if (this.addedNewKey !== '') {
        const obj = {
          text: !this.addedNewKey.includes(' -- [new]') ? `${this.addedNewKey} -- [new]` : this.addNewKey,
          value: this.addedNewKey,
        };
        this.selectedRoots = [this.addedNewKey];
        this.currentFeedRoots.push(obj);
        this.notNewKey = true;
        this.addedNewKey = '';
      }
    },
    extractFeedColumns(cols) {
      this.dataSetForColumns = this.currentFeedDetails.fields
        .filter(field => field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
        .map((field) => {
          field.text = field.fieldName;
          field.value = field.fieldName;
          return field;
        });

      if (this.selectedKeys && Object.keys(this.selectedKeys).length === 0) {
        this.showInputLoader = true;
        this.currentFeedDetails.fields
          .filter(field => !field.type.localeCompare('k', 'en', { sensitivity: 'base' }))
          .forEach((element) => {
            this.$set(this.selectedKeys, element.fieldName, '');
          });
      }
      this.$set(this, 'selectedColumns', cols);
      this.disableColumns = false;
      this.showInputLoader = false;
    },
    copyRows(isDisabled) {
      if (isDisabled) {
        return;
      }
      const rowToCopy = this.inputData.slice(this.currentRowToCopyIndex.start, this.currentRowToCopyIndex.end + 1);
      const copiedRows = [];
      rowToCopy.forEach((row) => {
        const copiedRow = JSON.parse(JSON.stringify(row));
        delete copiedRow._prevServerModel;
        delete copiedRow._currentServerModel;
        delete copiedRow._paramset;
        copiedRow.name = row.copiedRowName;
        copiedRow.rowName = row.copiedName;
        copiedRow.prevName = copiedRow.name;
        copiedRow.description = row.description;
        copiedRow.prevDescription = copiedRow.description;
        copiedRow._isModified = true;
        copiedRow.isNewRow = true;
        copiedRows.push(copiedRow);
      });
      this.inputData.splice(this.currentRowToCopyIndex.end + 1, 0, ...copiedRows);
      this.resetIndex();
      this.resetCopiedRowName();
      this.resetRowSelection();
      this.showCopyDialog = false;
    },
    resetIndex() {
      this.inputData.forEach((row, index) => {
        row.index = index;
      });
    },
    resetCopiedRowName() {
      this.inputData.forEach((row) => {
        row.copiedRowName = `Copy_of_${row.name}`;
      });
    },
    resetRowSelection() {
      this.inputData.forEach((row) => {
        row.checked = false;
      });
    },
    verifyBeforeDelete() {
      if (this.workflowInfo.wasWFRestarted) {
        this.wasWFRestarted = this.workflowInfo.wasWFRestarted;
        return false;
      }
      return true;
    },
    deleteRows() {
      if (this.verifyBeforeDelete()) {
        const alreadyAddedPsgIndex = {};
        this.psgToAdd.forEach((psg, index) => {
          alreadyAddedPsgIndex[psg.name] = index;
        });
        const rowToDelete = this.inputData.slice(this.currentRowToDeleteIndex.start, this.currentRowToDeleteIndex.end + 1);
        rowToDelete.forEach((row) => {
          if (row.name.length > 0 && row.isNewRow && Object.hasOwn(alreadyAddedPsgIndex, row.name)) {
            this.psgToAdd.splice(alreadyAddedPsgIndex[row.name], 1);
          } else if (row.name.length > 0) {
            this.deletedParameters.psgIds.push(row._paramset._id);
            if (this.workflowData.workFlowJobModel.runningJobs.includes(row._paramset._id)) {
              this.manager.deletedRunIds.push(row._paramset._id);
            }
          }
          this.inputData.splice(row.index, 1);
          this.resetIndex();
        });
        this.showDeleteDialog = false;
      }
    },
    updateCurrentInput() {
      if (this.currentInputToEdit.type === 'Var') {
        const variableValue = this.currentInputToEdit.body.value;
        const variableDataType = this.currentInputToEdit.body.type;
        if (variableValue === '') {
          this.varValueError = true;
          return true;
        } if (
          (variableDataType === 'number')
        && !(new RegExp(/[+-]?([0-9]*[.])?[0-9]+$/).test(variableValue))
        ) {
          this.varValueError = true;
          return true;
        } if (variableDataType === 'json') {
          try {
            const jsonObject = JSON.parse(variableValue);
          } catch (error) {
            this.varValueError = true;
            return true;
          }
        }
      }
      if (['timeSeries', 'forwardCurve'].includes(this.currentInputToEdit.body.type)) {
        const { body } = this.currentInputToEdit;
        body.cols = this.selectedColumns;
        if (this.currentInputToEdit.body.type === 'timeSeries') {
          body.key = this.selectedKeys;
        } else {
          body.key = this.selectedRoots;
        }
        body.feed = Array.isArray(this.selectedFeed) ? this.selectedFeed[0] : this.selectedFeed;
        body.source = Array.isArray(this.selectedSource) ? this.selectedSource[0] : this.selectedSource;
        body.prov = Array.isArray(this.selectedProvider) ? this.selectedProvider[0] : this.selectedProvider;
        body.data = {};
        body.data.expireSet = this.expireSet;
        body.data.expireTime = this.expireTime;
        body.data.enableCorrection = this.enableCorrection;
        // body.data.correctionData = this.correctionData;
        body.data.currentInputIsForwardCurve = (this.currentInputToEdit.body.isForwardCurve === 'true');

        this.currentInputToEdit.psg[this.currentInputToEdit.name] = body;
        let currentBubble = {};
        for (let i = 0; i < this.manager._priv.datasets.length; i++) {
          if (this.manager._priv.datasets[i]._name === this.currentInputToEdit.name) {
            currentBubble = this.manager._priv.datasets[i]._bbl;
            // this.manager._priv.datasets[i].correction_data = this.correctionData;
          }
        }

        const dataObj = {
          mgr: this.manager,
          varName: this.currentInputToEdit.name.trim(),
          isVarNameEdited: 0,
          bubble: currentBubble,
          dataset: this.manager._priv.datasets,
          displayed: {},
          firstRow: 0,
          lastRow: Math.max(0, this.manager._priv.psgBuffer.length - 1),
          feed: {
            name: this.currentInputToEdit.body.feed ? this.currentInputToEdit.body.feed : '',
            provider: this.currentFeedDetails.prov ? this.currentFeedDetails.prov : '',
            dataSource: this.currentInputToEdit.body.source ? this.currentInputToEdit.body.source : '',
            keys: this.currentFeedKeys,
          },
          type: (this.currentInputToEdit.body.isForwardCurve === 'true') ? 'Type.ROOTS' : 'Type.KEYS',
          cols: this.currentInputToEdit.body.cols,
          keyOrRoots: JSON.stringify(this.currentInputToEdit.body.key),
          isKeySet: !this.currentInputToEdit.body.isForwardCurve,
        };

        if (Object.hasOwn(this.currentInputToEdit.body, 'data')) {
          dataObj.expireSet = this.currentInputToEdit.body.data.expireSet;
          dataObj.expireTimeUi = this.currentInputToEdit.body.data.expireTime === '00:00'
            ? '' : this.currentInputToEdit.body.data.expireTime;
          dataObj.isEnableCorrection = this.currentInputToEdit.body.data.enableCorrection;
          dataObj.isCurrentInputIsForwardCurve = this.currentInputToEdit.body.data.currentInputIsForwardCurve || false;
        }

        try {
          if (!_editDatasetCB(dataObj, this)) {
            return true;
          }
        } catch (err) {
          console.log(err);
        }
      } else if (this.currentInputToEdit.body.type === 'date' && typeof this.currentInputToEdit.body.value !== 'string') {
        this.currentInputToEdit.body.value = this.convertDateToSave(this.currentInputToEdit.body.value);
        this.currentInputToEdit.psg[this.currentInputToEdit.name] = this.currentInputToEdit.body;
      } else {
        this.currentInputToEdit.psg[this.currentInputToEdit.name] = this.currentInputToEdit.body;
      }
      // -------------------------------
      return false;
    },
    convertDateToSave(date) {
      const mnth = (`0${ date.getMonth() + 1}`).slice(-2);
      const day = (`0${ date.getDate()}`).slice(-2);
      return [date.getFullYear(), mnth, day].join('-');
    },
    saveParameter(isDisabled) {
      if (isDisabled) {
        return;
      }
      if (this.updateCurrentInput()) {
        return;
      }
      this.varValueError = false;
      this.$set(this.inputData, this.currentInputToEdit.index, this.currentInputToEdit.psg);
      this.inputData[this.currentInputToEdit.index]._isModified = true;
      this.inputToggle = false;
    },
    checkKeysPopulated(feedKeys) {
      if (Object.keys(feedKeys).length > 0) {
        const keys = Object.keys(feedKeys);
        return !(keys.filter(key => feedKeys[key].length === 0).length > 0);
      }
      return false;
    },
    allParametersAdded(paramData) {
      if (this.inputFeedNotAvailable) {
        return false;
      }
      const {
        type, feed, source, columns, keys, roots, value,
      } = paramData;

      if (type === 'timeSeries') {
        if (feed.length === 0 || source.length === 0 || columns.length === 0 || !this.checkKeysPopulated(keys)) {
          return false;
        }
      } else if (type === 'forwardCurve') {
        if (feed.length === 0 || source.length === 0 || columns.length === 0 || roots.length === 0) {
          return false;
        }
      } else if (['number', 'boolean', 'date', 'object'].includes(type)) {
        if (value.length === 0) {
          return false;
        }
      }
      return true;
    },
    checkRowCompleted(row) {
      const keys = Object.keys(row);
      const objKeys = keys.filter(key => typeof row[key] === 'object').map(key => row[key]);
      const areAllColumnsFilled = objKeys.filter(col => this.allParametersAdded({
        type: col.type, feed: col.feed, source: col.source, columns: col.cols, keys: col.key, roots: col.key, value: col.value,
      }));
      return Object.keys(this.colTypes).length === areAllColumnsFilled.length;
    },
    async updateInputs() {
      const updateBody = {};
      updateBody.currentPsgVersion = this.workflowData.workFlowJobModel.psgVersion;
      updateBody.add = this.psgToAdd;
      updateBody.update = this.modifiedParameters;
      updateBody.delete = this.deletedParameters;
      updateBody.psgToRename = this.renamedRows;
      this.showMainLoader = true;
      await axios.post(`/api/workflows/${this.workflowId}/inputs`, updateBody)
        .then(async (response) => {
          if (response.status === 200) {
            await this.fetchWorkflow();
            this.showInputLoader = false;
            this.showMainLoader = false;
            this.inputToggle = false;
          }
        })
        .catch((error) => {
          // TODO: Show error pop up
        }).finally(() => {
          this.psgToAdd = [];
          this.modifiedParameters = [];
          this.deletedParameters.psgIds = [];
          this.showMainLoader = false;
          this.showChangeDialog = false;
        });
    },
    async fetchWorkflow() {
      this.getWorkflowStatus(this.workflowId).then(() => {
        this.workflowData = this.getParamStatus(this.workflowId);
        this.rawPsgModel = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
        this.showInputLoader = false;
        this.showMainLoader = false;
        this.inputToggle = false;
      }).catch((error) => {
        // this.$set(this.workflowParams, index, { workFlowJobModel: { parameterSetGroupModel: { parameterSetModels: [] } } });
      });
    },
    addNewRow(withNoName, isDisabled) {
      if (isDisabled) {
        return;
      }
      const newRow = JSON.parse(JSON.stringify(this.inputData[0]));
      delete newRow._paramset;
      delete newRow._currentServerModel;
      delete newRow._prevServerModel;
      if (withNoName) {
        newRow.name = '';
        newRow.prevName = '';
        newRow.copiedRowName = '';
        newRow.rowName = '';
        newRow.description = '';
        newRow.prevDescription = '';
      } else {
        newRow.name = this.newRowName;
        newRow.copiedRowName = `Copy_of_${this.newRowName}`;
      }
      newRow.isNewRow = true;
      newRow.index = this.inputData.length;
      newRow._isModified = false;
      Object.keys(newRow).forEach((key) => {
        const val = newRow[key];
        if (typeof val === 'object') {
          if (Object.hasOwn(val, 'isValueType') && val.isValueType) {
            val.value = '';
          } else {
            val.cols = '';
            val.feed = '';
            val.key = '';
            val.prov = '';
            val.source = '';
          }
        }
      });

      this.$set(this.inputData, this.inputData.length, newRow);
      this.showNewRowDialog = false;
    },
    startResize(index, event) {
      if (event) {
        this.resizingColumn = index;
        this.startX = event.pageX;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (event && this.resizingColumn !== null) {
        const offset = event.pageX - this.startX;
        const newWidth = `${Math.max(50, parseInt(this.headers[this.resizingColumn].style.width, 10) + offset) }px`;
        this.$set(this.headers[this.resizingColumn].style, 'width', newWidth);
        this.startX = event.pageX;
      }
    },
    handleMouseUp() {
      this.resizingColumn = null;
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    },
  },
};
</script>

<style>
#not-fullscreen .mds-modal__container___Mcd-ui{
  height: 500px !important;
  width: 600px !important;
  background-color: rgb(255, 179, 0);
}

#not-fullscreen .mds-section___Mcd-ui{
  height: 300px !important;
  width: 600px !important;
}
#search-results .mds-section___Mcd-ui {
  border: none !important;
  box-shadow: none !important;
}
.resizable-table {
  width: 100%;
  border-collapse: collapse;
}

.resizable-table .mds-th___Mcd-ui {
  padding: 8px;
}
.resizable-table .mds-thead__th-selection___Mcd-ui {
  padding-left: 0.5%;
}
.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 100%;
  cursor: ew-resize;
  background-color: transparent;
}
.resizable-table .mds-thead__th-numbered___Mcd-ui {
  width: 39px;
  padding: 0px !important;
}
/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}
::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 6px; /* Round the corners of the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
}
#search-bar {
  position: sticky;
  top: 0;
  background-color: #ffffff;
}
#inputs-table tbody {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
}

.table-body-cell-data {
  background: #f2e6f0 !important;
}

.table-body-cell-publish {
  background: #fef9e5 !important;
}

.table-body-cell-var {
  background: #e5f7eb !important;
}
</style>
