<template>
  <div class="wrapper-column--key">
    <mds-section
      class="section-wrapper"
      title="Keys"
      border-position="bottom"
    >
      <template
        v-if="check === false"
        #mds-section-actions
      >
        <mds-pagination
          v-if="totalItems"
          :total-items="totalItems"
          :page-size="pageSize"
          :page-sizes="[50]"
          :page="page"
          :icon-only="true"
          size="small"
          @mds-pagination-page-changed="onPaginationPageChanged"
        />
      </template>
      <mds-search-field
        v-model="searchKeyInput"
        placeholder="Search for Keys"
        label="Search"
        class="clickable-search-field"
        @keyup.native="clearStateToKeys(searchKeyInput)"
        @mds-search-field-input-cleared="clearSearchBar"
      />
    </mds-section>
    <div class="list-content-wrapper">
      <mds-table
        size="medium"
        multiselection
        row-hover
      >
        <mds-thead
          :checked="selectAll"
          @mds-thead-multiselection-changed="toggleSelectAll($event)"
        >
          <mds-th
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody :key="tableKey">
          <mds-tr
            v-for="(key, index) in filteredKeys"
            :key="index"
            :checked="key.checked"
            @click.native="onClick(key, index, $event)"
          >
            <mds-td
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ key[header.fieldName] }}
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </div>
  </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';
import MdsSection from '@mds/section';
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import MdsPagination from '@mds/pagination';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FeedKeyDataDelete',
  components: {
    MdsPagination,
    MdsSection,
    MdsSearchField,
    MdsTable,
    MdsTh,
    MdsThead,
    MdsTbody,
    MdsTr,
    MdsTd,
  },
  data() {
    return {
      feedName: '',
      page: 1,
      pageSize: 50,
      searchKeyInput: '',
      check: false,
      loading: false,
      previousNode: '',
      selectedItemsMap: {},
      selectAll: false,
      tableKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      keys: 'dashboardModule/allKeys',
      totalItems: 'dashboardModule/allItems',
      keySearch: 'dashboardModule/allKeySearchRes',
    }),
    // creates headers and map them
    headers() {
      let uniqueKeys = [];
      // headers and keys
      if (uniqueKeys.length === 0 && this.check !== true) {
        uniqueKeys = Object.keys(this.keys.reduce((result, obj) => Object.assign(result, obj), {}));
        // headers and key search result
      } else if (uniqueKeys.length === 0 && this.check === true && this.loading === false) {
        uniqueKeys = Object.keys(this.keySearch.reduce((result, obj) => Object.assign(result, obj), {}));
      }
      uniqueKeys = uniqueKeys.filter(item => item !== 'checked' && item !== 'feedName'
        && item !== 'Id' && item !== 'column' && item !== 'selectedCols' && item !== 'key');
      const arr = [];
      uniqueKeys.forEach((key) => {
        let colDef = {};
        if (key !== 'Description') {
          colDef = {
            text: key,
            fieldName: key,
          };
        }
        arr.push(colDef);
      });
      return arr;
    },
    // Transform searchResultsOrKeys to include a combined string of all values for filtering
    combinedKeys() {
      return this.keys.map(key => ({
        ...key,
        combinedString: this.headers
          .filter(
            header => !['feedName', 'Description', 'checked', 'Id'].includes(header.fieldName),
          )
          .map(header => key[header.fieldName] || '')
          .join(' ')
          .toLowerCase(),
      }));
    },
    // Filter rows based on the searchKeyInput
    filteredKeys() {
      return this.searchKeyInput === ''
        ? this.combinedKeys
        : this.combinedKeys.filter(key => key.combinedString.includes(this.searchKeyInput.toLowerCase()));
    },
  },
  created() {
    this.$eventBus.$on('initialKeysRes', (data) => {
      this.check = data;
    });
  },
  mounted() {
    this.$eventBus.$on('feedNameSelected', (data) => {
      this.clearState();
      this.$eventBus.$emit('deleteKeyValueMap', this.selectedItemsMap);
      this.feedName = data;
    });
    this.$eventBus.$on('defaultPage', (data) => {
      this.page = data;
    });
    this.$eventBus.$on('keyDeleted', (data) => {
      this.clearState();
    });
  },
  methods: {
    ...mapActions('dashboardModule',
      ['getKeyData']),
    onPaginationPageChanged(paginationConfig) {
      this.clearState();
      this.$eventBus.$emit('deleteKeyValueMap', this.selectedItemsMap);
      this.page = paginationConfig.page;
      this.getKeyData({ feedName: this.feedName, pageNo: this.page });
    },
    onClick(selectedKey, index, ev, isSelectAll = false) {
      const isChecked = isSelectAll || ev.target.checked;
      if (isChecked) {
      // constructing the api query param
        this.feedName = selectedKey.feedName;
        let keyValue = '';
        if (Object.keys(selectedKey).length > 0) {
          Object.entries(selectedKey).forEach(([key, value]) => {
            if (key !== 'Description' && key !== 'checked' && key !== 'feedName'
            && key !== 'Id' && key !== 'column' && key !== 'selectedCols' && key !== 'key' && key !== 'combinedString') {
              keyValue += `${key}=${value}&`;
            }
          });
          keyValue = keyValue.slice(0, -1);
          this.$set(this.selectedItemsMap, index, keyValue);
        }
      } else {
        // Remove the item from the map if unchecked
        this.$delete(this.selectedItemsMap, index);
      }
      this.$eventBus.$emit('deleteKeyValueMap', this.selectedItemsMap);
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      this.filteredKeys.forEach((key, index) => {
        key.checked = this.selectAll; // Update the `checked` state of each key
        this.onClick(key, index, { target: { checked: this.selectAll } }, true);
      });
      if (!this.selectAll) {
        this.selectedItemsMap = {};
      }
      this.$eventBus.$emit('deleteKeyValueMap', this.selectedItemsMap);
    },
    clearState() {
      this.selectAll = false;
      this.selectedItemsMap = {};
      this.filteredKeys.forEach((key, index) => {
        key.checked = false;
      });
      this.tableKey += 1;
    },
    clearSearchBar() {
      this.check = false;
    },
    clearStateToKeys(input) {
      if (this.keys.length > 0 && input === '') {
        this.check = false;
      }
    },
    keyValues(keyObj) {
      let temp = '';
      Object.entries(keyObj).forEach(([key, value]) => {
        if (key !== 'Description' && key !== 'feedName' && key !== 'checked' && key !== 'Id'
          && key !== 'column' && key !== 'selectedCols' && key !== 'key') {
          temp += `${key}=${value};`;
        }
      });
      return temp.substring(0, temp.length - 1);
    },
  },
};
</script>
<style lang='scss'>
@import '~@mds/constants';
@import '~@mds/fonts';
@import '~@mds/typography';

.wrapper-column--key {
  box-sizing: border-box;
  height: calc(90vh);
  background-color: white;
  width: 350px;
  flex-grow: 2;
  border: 7px solid whitesmoke;
  display: flex;
  flex-direction: column;
}
.list-content th label {
  visibility: hidden;
}
</style>
