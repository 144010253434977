<template>
  <div>
    <div v-if="selectedInputs.length > 0">
      <hr class="horizontal-bar">
      <mds-layout-grid class="border-top-solid">
        <mds-row class="pading-top_05rem">
          <mds-col :cols="6">
            <div><b>Source Workflow</b></div>
            <div style="margin-top: .75rem;">
              {{ `${sourceWorkflow.id} - ${sourceWorkflow.name}` }}
            </div>
            <hr>
            <div><b>Selected Input Rows</b></div>
            <ul>
              <li
                v-for="(selectedInput, idx) in selectedInputs"
                :key="idx"
              >
                {{ selectedInput }}
              </li>
            </ul>
          </mds-col>
          <mds-col :cols="6">
            <mds-select
              v-model="selectedTargetWorkflow"
              label="Target Workflow"
              placeholder="Target Workflow"
              :options="inputSetMigrationTargetDataset"
              :disabled="showMainLoader"
              @change="selectParamStatus(selectedTargetWorkflow)"
            />
            <small style="font-size: 15px">
              <b>Note:</b> Please be advised the Target Workflow should be added to the workflows dashboard for it to appear in the above dropdown if not.
            </small>
          </mds-col>
          <mds-col :cols="12">
            <mds-row>
              <mds-col>
                <mds-table
                  v-if="currentParamsetData.dsMap && sourceWorkflow.id != selectedTargetWorkflow
                    && sourceWorkflowParameterSets.length > 0
                    && schemaValidationResult.errors.length === 0
                    && schemaValidationResult.nonExistentSourceKeys.length === 0
                    && schemaValidationResult.nonExistentTargetKeys.length === 0"
                  zebra-stripes
                >
                  <mds-thead>
                    <mds-th> Variable Name</mds-th>
                    <mds-th> Source </mds-th>
                    <mds-th> Feed </mds-th>
                    <mds-th>
                      Key values / Roots
                      <mds-button
                        id="tooltip-trigger"
                        size="small"
                        variation="icon-only"
                        icon="question-circle"
                        type="button"
                        @mouseover="toolTipToggle=true"
                        @focus="toolTipToggle=true"
                      />
                      <mds-tooltip
                        id="my-tooltip"
                        v-model="toolTipToggle"
                        size="small"
                        triggered-by="tooltip-trigger"
                        :position="['top-center']"
                        style="white-space: pre-line; line-height: 0.2; display: block; padding: 0;"
                      >
                        {{ $t('allHomeData.products.workflowManager.curveMigrateTooltipText') }}
                      </mds-tooltip>
                    </mds-th>
                    <mds-th> Columns </mds-th>
                  </mds-thead>
                  <mds-tbody>
                    <template
                      v-for="(row, index) in Object.keys(currentParamsetData.dsMap)"
                    >
                      <mds-tr :key="index">
                        <mds-td>
                          Current {{ row }}
                        </mds-td>
                        <mds-td>
                          {{ currentParamsetData.dsMap[row].source }}
                        </mds-td>
                        <mds-td>
                          {{ currentParamsetData.dsMap[row].feed }}
                        </mds-td>
                        <mds-td>
                          {{ currentParamsetData.dsMap[row].key }}
                        </mds-td>
                        <mds-td>
                          {{ currentParamsetData.dsMap[row].cols }}
                        </mds-td>
                      </mds-tr>
                      <mds-tr :key="`target_` + index">
                        <mds-td>
                          Target {{ row }}
                        </mds-td>
                        <mds-td>
                          <textarea
                            v-model="targetParamsetData.dsMap[row].source"
                            spellcheck="false"
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            :style="{ borderColor: getVarBorderColor(row, 'source') }"
                          />
                        </mds-td>
                        <mds-td>
                          <textarea
                            v-model="targetParamsetData.dsMap[row].feed"
                            spellcheck="false"
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            :style="{ borderColor: getVarBorderColor(row, 'feed') }"
                          />
                        </mds-td>
                        <mds-td>
                          <textarea
                            v-model="targetParamsetData.dsMap[row].key"
                            spellcheck="false"
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            :style="{ borderColor: getVarBorderColor(row, 'key') }"
                          />
                        </mds-td>
                        <mds-td>
                          <textarea
                            v-model="targetParamsetData.dsMap[row].cols"
                            spellcheck="false"
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            :style="{ borderColor: getVarBorderColor(row, 'cols') }"
                          />
                        </mds-td>
                      </mds-tr>
                    </template>
                  </mds-tbody>
                </mds-table>
              </mds-col>
            </mds-row>
            <mds-button
              v-if="
                sourceWorkflow.id != selectedTargetWorkflow
                  && sourceWorkflowParameterSets.length > 0
                  && schemaValidationResult.errors.length === 0
                  && schemaValidationResult.nonExistentSourceKeys.length === 0
                  && schemaValidationResult.nonExistentTargetKeys.length === 0
              "
              variation="primary"
              icon-right="jump-to"
              :loading="migrationInProgressFlag"
              :disabled="migrationInProgressFlag"
              style="margin-top: 1rem;"
              @click="migrateInputs()"
            >
              Migrate
            </mds-button>
          </mds-col>
          <mds-col
            :cols="12"
            style="margin-top: 1rem"
          >
            <mds-banner
              v-if="showMigrationDialogErrorMsg"
              variation="error"
              persistent
            >
              {{ migrationDialogErrorMsg }}
            </mds-banner>
            <mds-banner
              v-if="showMigrationDialogSuccessMsg"
              persistent
            >
              {{ migrationDialogSuccessMsg }}
            </mds-banner>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <mds-alert
        v-if="sourceWorkflow.id && sourceWorkflow.id == selectedTargetWorkflow"
        variation="warning"
        title="Same Workflows Issue"
        tinted
        persistent
      >
        Please select different workflows to migrate input sets.
      </mds-alert>
      <div
        v-if="!(sourceWorkflowParameterSets.length > 0
          && schemaValidationResult.errors.length === 0
          && schemaValidationResult.nonExistentSourceKeys.length === 0
          && schemaValidationResult.nonExistentTargetKeys.length === 0)"
        style="margin-top: 1rem;"
      >
        <mds-layout-grid>
          <mds-row class="pading-top_05rem">
            <mds-col :cols="6">
              <mds-table v-if="schemaValidationResult.combinedSourceKeys.length > 0">
                <mds-thead>
                  <mds-th>
                    Variables
                  </mds-th>
                  <mds-th>
                    Status
                  </mds-th>
                </mds-thead>
                <mds-tbody>
                  <template v-for="(key, idx) in schemaValidationResult.combinedSourceKeys">
                    <mds-tr
                      v-if="schemaValidationResult.combinedSourceKeys.length > 0"
                      :key="idx + Math.random()"
                    >
                      <mds-td class="input-td">
                        {{ key }}
                      </mds-td>
                      <mds-td class="input-td">
                        Available
                      </mds-td>
                    </mds-tr>
                  </template>
                  <template v-for="(keyII, idxII) in schemaValidationResult.nonExistentSourceKeys">
                    <mds-tr
                      v-if="schemaValidationResult.nonExistentSourceKeys[0] !== -1"
                      :key="idxII + Math.random()"
                    >
                      <mds-td class="red-tint input-td">
                        {{ keyII }}
                      </mds-td>
                      <mds-td class="red-tint input-td">
                        Not Available
                      </mds-td>
                    </mds-tr>
                  </template>
                </mds-tbody>
              </mds-table>
            </mds-col>
            <mds-col :cols="6">
              <mds-table v-if="schemaValidationResult.combinedTargetKeys.length > 0">
                <mds-thead>
                  <mds-th>Variables</mds-th>
                  <mds-th>Status</mds-th>
                </mds-thead>
                <mds-tbody>
                  <template v-for="(key, idx) in schemaValidationResult.combinedTargetKeys">
                    <mds-tr
                      v-if="schemaValidationResult.combinedTargetKeys.length > 0"
                      :key="idx + Math.random()"
                    >
                      <mds-td class="input-td">
                        {{ key }}
                      </mds-td>
                      <mds-td class="input-td">
                        Available
                      </mds-td>
                    </mds-tr>
                  </template>
                  <template v-for="(keyII, idxII) in schemaValidationResult.nonExistentTargetKeys">
                    <mds-tr
                      v-if="schemaValidationResult.nonExistentTargetKeys[0] !== -1"
                      :key="idxII + Math.random()"
                    >
                      <mds-td class="red-tint input-td">
                        {{ keyII }}
                      </mds-td>
                      <mds-td class="red-tint input-td">
                        Not Available
                      </mds-td>
                    </mds-tr>
                  </template>
                </mds-tbody>
              </mds-table>
            </mds-col>
          </mds-row>
          <mds-alert
            v-if="schemaValidationResult.errors.length > 0 && schemaValidationResult.errors[0] !== -1"
            style="margin-top: 1rem;"
            variation="warning"
            :list="schemaValidationResult.errors"
            title="Please Note:"
            size="medium"
            tinted
            persistent
          />
        </mds-layout-grid>
      </div>
    </div>
    <mds-alert
      v-else
      style="margin-top: 2rem;"
      variation="error"
      title="No Input Rows Selected"
      tinted
      persistent
    >
      Please select atleast 1 input row to migrate inputs.
    </mds-alert>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsSelect from '@mds/select';
import MdsAlert from '@mds/alert';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsButton } from '@mds/button';
import MdsBanner from '@mds/banner';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { getUserName } from '../../../utils/authService';

export default {
  name: 'WorkflowInputSetMigration',
  components: {
    MdsLayoutGrid,
    MdsCol,
    MdsRow,
    MdsSelect,
    MdsAlert,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsBanner,
  },
  props: {
    sourceWorkflow: {
      type: Object,
      default: null,
    },
    selectedInputs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      migrationInProgressFlag: false,
      showMigrationDialogErrorMsg: false,
      showMigrationDialogSuccessMsg: false,
      migrationDialogErrorMsg: '',
      migrationDialogSuccessMsg: '',
      showMainLoader: false,
      loggedInUser: getUserName(),
      inputSetMigrationWorkflows: {
        source: [],
        target: [],
      },
      selectedSourceWorkflow: '',
      selectedTargetWorkflow: '',
      inputSetMigrationSourceDataset: [],
      inputSetMigrationTargetDataset: [],
      sourceWorkflowJobModel: {},
      sourceWorkflowParameterSets: [],
      targetWorkflowJobModel: {},
      targetWorkflowParameterSets: [],
      selectedInputSetMap: {},
      selectAllInputSets: false,
      schemaValidationResult: {
        errors: [-1],
        nonExistentSourceKeys: [-1],
        nonExistentTargetKeys: [-1],
        combinedSourceKeys: [],
        combinedTargetKeys: [],
      },
      goodToMigrateInputs: false,
      targetWorkflowId: null,
      importFeedsToBeChanged: '',
      importFeedSourcesToBeChanged: '',
      currentParamsetData: {},
      targetParamsetData: {},
      tooltipText: 'For timeseries please provide key value pairs, e.g. { "keyname1" : "value1", "keyname2" : "value2" }\n'
                    + 'For forward curve (futures contracts) please provide array of roots, e.g. ["rootname1", "rootname2"]',
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getUserWorkflows', 'getParamStatus']),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('workflowModule', ['getWorkflowStatus']),
    init() {
      this.inputSetMigrationWorkflows.target = this.getWorkflowsForUser({
        shouldBeSameOwner: false,
        specificPermissions: ['edit', 'input_edit'],
      }).map(({
        id, name, psgId, psgVersion, workflowNameToShow,
      }) => ({
        id, name, psgId, psgVersion, workflowNameToShow,
      }));

      this.inputSetMigrationTargetDataset = this.inputSetMigrationWorkflows.target
        .map(wf => ({ text: wf.workflowNameToShow, value: wf.id }));

      this.sourceWorkflowJobModel = this.getParamStatus(this.sourceWorkflow.id).workFlowJobModel;
      this.sourceWorkflowParameterSets = this.sourceWorkflowJobModel.parameterSetGroupModel.parameterSetModels
        .filter(pm => this.selectedInputs.includes(pm.name));
      [this.currentParamsetData] = this.getInputSetFields(this.sourceWorkflowParameterSets);
      this.targetParamsetData = JSON.parse(JSON.stringify(this.currentParamsetData));
    },
    getVarBorderColor(varName, keyName) {
      if (this.currentParamsetData.dsMap[varName][keyName] !== this.targetParamsetData.dsMap[varName][keyName]) {
        return '#008dde';
      }
      return '#718571';
    },
    getUniqueFeedsForImportingWorkflow(parameterSetModels) {
      const feedObjects = [];
      const dataSourceObjects = [];
      parameterSetModels.forEach((psm) => {
        const { parameterModels } = psm;
        const feedNames = parameterModels
          .filter(pm => pm.propKey.includes('.feed'))
          .map(tpmf => tpmf.propValue);
        const dataSourceNames = parameterModels
          .filter(pm => pm.propKey.includes('.source'))
          .map(tpmf => tpmf.propValue);
        feedObjects.push(...feedNames);
        dataSourceObjects.push(...dataSourceNames);
      });

      return {
        feedNames: Array.from(new Set(feedObjects)),
        sourceNames: Array.from(new Set(dataSourceObjects)),
      };
    },
    replaceFeedsAndSources(feeds, sources, inputSetPayload) {
      let stringifiedPSM = JSON.stringify(inputSetPayload);
      sources.forEach((source) => {
        stringifiedPSM = stringifiedPSM.replace(new RegExp(`"${source.before}"`, 'g'), `"${source.after}"`);
      });
      feeds.forEach((feed) => {
        stringifiedPSM = stringifiedPSM.replace(new RegExp(`"${feed.before}"`, 'g'), `"${feed.after}"`);
      });
      return JSON.parse(stringifiedPSM);
    },
    replacePayloadWithTargetCurve(payload) {
      console.log(payload);
      payload[0].parameterModels.filter(pms => pms.propKey.startsWith('udef.ds.')).forEach((p) => {
        const dsRegexResult = /udef.ds.([A-Za-z0-9_]*).(.*)/gm.exec(p.propKey);
        const varName = dsRegexResult[1];
        const varType = dsRegexResult[2];

        p.propValue = this.targetParamsetData.dsMap[varName][varType];
      });
    },
    prepareImportFeedsToMigrate(feedNames, sourceNames) {
      this.importFeedsToBeChanged = feedNames
        .map(f => `${f} ~ ${f}`)
        .join('\n');
      this.importFeedSourcesToBeChanged = sourceNames
        .map(f => `${f} ~ ${f}`)
        .join('\n');
    },
    getWorkflowsForUser(options = {
      specificPermissions: [],
      shouldBeSameOwner: false,
    }) {
      const { specificPermissions, shouldBeSameOwner } = options;
      let userWorkflowsOfType = this.getUserWorkflows;
      if (specificPermissions.length > 0) {
        userWorkflowsOfType = userWorkflowsOfType
          .filter(wf => specificPermissions.some(permission => wf.permissions.includes(permission)));
      }
      if (shouldBeSameOwner) {
        userWorkflowsOfType = userWorkflowsOfType.filter(wf => wf.owner === this.loggedInUser);
      }

      return userWorkflowsOfType;
    },
    async selectParamStatus(workflowId) {
      this.targetWorkflowId = workflowId;
      this.showMainLoader = true;
      this.showMigrationDialogErrorMsg = false;
      if (!this.getParamStatus(workflowId)) {
        try {
          await this.getWorkflowStatus(workflowId);
        } catch (error) {
          this.showMigrationDialogErrorMsg = true;
          this.migrationDialogErrorMsg = `Unable to fetch status call for workflow - ${workflowId}`;
        }
      }
      if (!this.showMigrationDialogErrorMsg) {
        const { feedNames, sourceNames } = this.getUniqueFeedsForImportingWorkflow(this.sourceWorkflowParameterSets);
        this.prepareImportFeedsToMigrate(feedNames, sourceNames);
        this.targetWorkflowJobModel = this.getParamStatus(workflowId).workFlowJobModel;
        this.targetWorkflowParameterSets = this.targetWorkflowJobModel.parameterSetGroupModel.parameterSetModels;

        if (this.sourceWorkflowParameterSets.length > 0 && this.targetWorkflowParameterSets.length > 0) {
          const sourceParamSchema = this.getInputSetFields(this.sourceWorkflowParameterSets);
          const targetParamSchema = this.getInputSetFields(this.targetWorkflowParameterSets);
          this.schemaValidationResult = this.validateInputSetSchemas(sourceParamSchema, targetParamSchema);
        }
      }
      this.showMainLoader = false;
    },
    getInputSetFields(parameterSetModels) {
      const fieldsArray = [];
      const ds = parameterSetModels[0].parameterModels.filter(p => p.propKey.includes('udef.ds'));
      const vars = parameterSetModels[0].parameterModels.filter(p => p.propKey.includes('udef.var'));
      const dsMap = {};
      const varsMap = {};

      ds.forEach((d) => {
        const dsRegexResult = /udef.ds.([A-Za-z0-9_]*).(.*)/gm.exec(d.propKey);
        const varName = dsRegexResult[1];
        const varType = dsRegexResult[2];

        if (!dsMap[varName]) {
          dsMap[varName] = {};
        }

        if (dsMap[varName]) {
          dsMap[varName][varType] = d['propValue'];
        }
      });

      vars.forEach((v) => {
        const vRegexResult = /udef.var.(.*)/gm.exec(v.propKey);
        const varName = vRegexResult[1];
        varsMap[varName] = v['propValue'];
      });

      fieldsArray.push({
        dsMap,
        varsMap,
      });

      return fieldsArray;
    },
    findNonExistingValues(sourceKeys, targetKeys) {
      const nonExistentSourceKeys = [];
      const nonExistentTargetKeys = [];

      sourceKeys.forEach((ele) => {
        if (!targetKeys.includes(ele)) {
          nonExistentTargetKeys.push(ele);
        }
      });

      targetKeys.forEach((ele) => {
        if (!sourceKeys.includes(ele)) {
          nonExistentSourceKeys.push(ele);
        }
      });

      return {
        nonExistentSourceKeys,
        nonExistentTargetKeys,
      };
    },
    validateInputSetSchemas(sourceParamSchema, targetParamSchema) {
      const sourceParamSchemaDSKeys = Object.keys(sourceParamSchema[0].dsMap);
      const targetParamSchemaDSKeys = Object.keys(targetParamSchema[0].dsMap);
      const sourceParamSchemaVarsKeys = Object.keys(sourceParamSchema[0].varsMap);
      const targetParamSchemaVarsKeys = Object.keys(targetParamSchema[0].varsMap);

      const errors = [];
      const combinedSourceKeys = [
        ...sourceParamSchemaDSKeys,
        ...sourceParamSchemaVarsKeys,
      ];
      const combinedTargetKeys = [
        ...targetParamSchemaDSKeys,
        ...targetParamSchemaVarsKeys,
      ];

      const { nonExistentSourceKeys, nonExistentTargetKeys } = this.findNonExistingValues(combinedSourceKeys, combinedTargetKeys);

      if (combinedSourceKeys.length !== combinedTargetKeys.length) {
        errors.push(
          'No of columns do not match for both Source and Target Input sets',
        );
        errors.push(
          'Please make sure the Source and Target Input Columns have matching names and are Case-Sensitive',
        );
      }

      if (combinedSourceKeys.length < combinedTargetKeys.length) {
        errors.push(
          'Source Inputs does not have required columns compared to Target Inputs',
        );
      }

      if (combinedSourceKeys.length > combinedTargetKeys.length) {
        errors.push(
          'Target Inputs does not have required columns compared to Source Inputs',
        );
      }

      if (
        combinedSourceKeys.length === combinedTargetKeys.length
          && (nonExistentSourceKeys.length > 0 || nonExistentTargetKeys.length > 0)
      ) {
        errors.push(
          'Please make sure the Source and Target Input Columns have matching names and are Case-Sensitive',
        );
      }

      this.showMigrationDialogSuccessMsg = false;
      this.showMigrationDialogErrorMsg = false;

      return {
        errors,
        nonExistentSourceKeys,
        nonExistentTargetKeys,
        combinedSourceKeys,
        combinedTargetKeys,
      };
    },
    async migrateInputs() {
      this.migrationInProgressFlag = true;

      const inputSetPayload = this.sourceWorkflowParameterSets.map(swp => ({
        description: swp.description,
        name: swp.name,
        parameterModels: swp.parameterModels.map(pm => ({
          propKey: pm.propKey,
          propValue: pm.propValue,
        })),
      }));

      this.replacePayloadWithTargetCurve(inputSetPayload);

      const updateInputSetPayload = {
        add: inputSetPayload,
        currentPsgVersion: this.targetWorkflowJobModel.psgVersion,
        delete: { psgIds: [], paramIds: [] },
        psgToRename: [],
        update: [],
      };

      try {
        const updateTargetInputResponse = await axios.post(
          `/api/workflows/${this.targetWorkflowId}/inputs`,
          updateInputSetPayload,
        );

        if (updateTargetInputResponse.status === 200) {
          const {
            name, timeZone, targets, ui,
            description, psgId, psgVersion,
            correctionDays, id, owner,
          } = this.sourceWorkflow;

          const updateWorkflowPayload = {
            name,
            timeZone,
            targets,
            ui,
            description,
            psgId,
            psgVersion,
            correctionDays,
            id,
            owner,
            comment: `Input Migration ${this.selectedInputs.join(',')} to Workflow: ${this.targetWorkflowId}`,
          };

          const updateWorkflowResponse = await axios.put(
            `/api/workflows/updateById/${this.sourceWorkflow.id}`,
            updateWorkflowPayload,
          );

          if (updateWorkflowResponse.status === 200) {
            await this.getWorkflowStatus(this.targetWorkflowId);
            this.showMigrationDialogErrorMsg = false;
            this.migrationDialogErrorMsg = '';
            this.migrationInProgressFlag = false;
            this.showMigrationDialogSuccessMsg = true;
            // eslint-disable-next-line max-len
            this.migrationDialogSuccessMsg = `Successfully migrated input ${this.selectedInputs.length > 3 ? `${this.selectedInputs.slice(0, 3).join(',')}...` : this.selectedInputs.join(',') } to workflow ${this.targetWorkflowId}`;
          }
        } else {
          this.showMigrationDialogSuccessMsg = false;
          this.showMigrationDialogErrorMsg = true;
          this.migrationDialogErrorMsg = 'Failed to migrate input, check if the input already exists.';
        }
      } catch (error) {
        this.showMigrationDialogSuccessMsg = false;
        this.showMigrationDialogErrorMsg = true;
        this.migrationDialogErrorMsg = `Failed to migrate input, check if the input already exists. ${error.toString()}`;
      } finally {
        this.migrationInProgressFlag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

textarea {
  @include mds-body-text-m($bold: false);
  resize: vertical;
  width: 100%;
}
.source-input-set-section {
  max-height: 50vh;
  overflow: auto;
  padding: 0.5rem;
  border: 1px solid #f0f0f0;
}
.red-tint {
  background: #fccdcd !important;
}
.green-tint {
  background: #bbf8cf !important;
}
.no-pointer-events {
  pointer-events: none;
}
.input-td {
  padding: 0.5rem !important;
}
</style>
